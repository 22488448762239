import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { i18nService } from "api/services";
import { RootState } from "app";

export type I18nState = {
  translations?: Record<string, string>;
  languages: Record<string, string>;
};

const initialState: I18nState = {
  languages: {},
};

export const getTranslations = createAsyncThunk(
  "i18n/translations",
  async (languageCode: string, thunkAPI) => {
    return await i18nService.getTranslations(languageCode);
  }
);

export const getLanguages = createAsyncThunk(
  "i18n/languages",
  async (_, thunkAPI) => {
    return await i18nService.getLanguages();
  }
);

const i18nSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getTranslations.fulfilled,
      (state, action: PayloadAction<Record<string, string> | null>) => {
        if (action.payload) state.translations = action.payload;
      }
    );
    builder.addCase(
      getLanguages.fulfilled,
      (state, action: PayloadAction<Record<string, string> | null>) => {
        if (action.payload) state.languages = action.payload;
      }
    );
  },
});

const selectSelf = (state: RootState) => state.i18n;
export const selectT9N = createDraftSafeSelector(
  selectSelf,
  (state) => state.translations
);
export const selectLanguages = createDraftSafeSelector(
  selectSelf,
  (state) => state.languages
);

export default i18nSlice.reducer;
