import { ProductRequest, ProductsByType } from "api/models";
import utils from "api/utils";

const baseUrl = "api/product";

const getProducts = async (request: ProductRequest) => {
  return utils.apiGet<ProductsByType>(baseUrl, request);
};

const productService = { getProducts };
export default productService;
