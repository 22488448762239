import { Box, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, FieldValues } from "react-hook-form";
import { formatService } from "services";
import { FormProps } from "shared/components/form";

type Props<T> = FormProps<T> &
  TextFieldProps & {
    quantity?: number;
    factor?: number;
  };

const calculateConsumptionLiters = (grams?: number, factor?: number) => {
  return grams && factor ? grams / (factor * 1000) : undefined;
};

const ProductConsumptionField = <T extends FieldValues>({
  name,
  control,
  label,
  quantity,
  factor,
  ...rest
}: Props<T>) => {
  const [quantityLiters, setQuantityLiters] = useState<string | number>(0);

  const onChangeToLiters = useCallback(
    (event: React.ChangeEvent<any>) => {
      const valueLiters = calculateConsumptionLiters(+event.target.value, factor) ?? 0;
      setQuantityLiters(formatService.number(valueLiters, 3));
    },
    [factor]
  );

  useEffect(() => {
    if (quantity) {
      setQuantityLiters(quantity);
    }
  }, [quantity,setQuantityLiters])

  if (!name) return <></>;

  return (
    <Box display="flex">
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { isTouched, error },
        }) => (
          <TextField
            value={value ?? ""}
            onChange={(e) => {
              onChange(e);
              onChangeToLiters(e);
            }}
            onBlur={onBlur}
            label={label}
            error={isTouched && Boolean(error?.message)}
            helperText={isTouched && error?.message}
            type="number"
            inputProps={{
              step: 1,
              min: 0,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">gr</InputAdornment>,
            }}
            sx={{ mr: 1 }}
            {...rest}
          />
        )}
      />

      <TextField
        value={quantityLiters}
        disabled
        InputProps={{
          endAdornment: <InputAdornment position="end">L</InputAdornment>,
        }}
        {...rest}
        inputProps={{
          step: 0.001,
        }}
      />
    </Box>
  );
};

export default ProductConsumptionField;
