export type Order = "asc" | "desc";

function getComparer<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparer(a, b, orderBy)
    : (a, b) => -descendingComparer(a, b, orderBy);
}

function sort<T>(array: T[], comparator: (a: T, b: T) => number) {
  return array.sort((a, b) => comparator(a, b));
}

function descendingComparer<T>(a: T, b: T, orderBy: keyof T) {
  let valA: T[keyof T] | string = a[orderBy];
  let valB: T[keyof T] | string = b[orderBy];

  if (typeof valA === "string")
    valA = valA.toLocaleLowerCase();
  if (typeof valB === "string")
    valB = valB.toLocaleLowerCase();

  if (!valA) return -1;
  if (!valB) return 1;

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

const sortService = { getComparer, sort };
export default sortService;
