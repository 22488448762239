import { CssBaseline, ThemeProvider } from "@mui/material";
import { routes } from "app/routing";
import AuthSuspense from "features/authentication/AuthSuspense";
import { selectApplication } from "features/configuration";
import { selectT9N } from "features/locale";
import Localization from "features/locale/Localization";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { cookieKeys, cookieService } from "services";
import { theme } from "themes";

import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./components/Layout";
import { useAppSelector } from "./hooks";
import { RoutesRenderer } from "./routing/RoutesRenderer";

const updateDomHeader = () => {
  var version = cookieService.get(cookieKeys.version);
  if (version) upsertMetaTag("version", version);
};

const upsertMetaTag = (name: any, content: string) => {
  var meta = document.getElementsByTagName("meta")[name];
  if (meta) meta.content = content;
};

const App = () => {
  const application = useAppSelector(selectApplication);
  const t9n = useAppSelector(selectT9N);

  useEffect(() => {
    updateDomHeader();
  }, []);

  useEffect(() => {
    if (application && t9n) document.title = t9n[application];
  }, [application, t9n]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AuthSuspense>
          <Localization>
            <CssBaseline />
            <BrowserRouter>
              <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                  <RoutesRenderer routes={routes} />
                </Suspense>
              </Layout>
            </BrowserRouter>
          </Localization>
        </AuthSuspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
