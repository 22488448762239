import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps,
} from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { FormProps } from "./form.props";

type Props<T> = FormProps<T> &
  RadioGroupProps & {
    label?: string;
    options?: {
      value: any;
      label: string;
      children?: React.ReactNode;
    }[];
  };

export const ControlledRadioGroup = <T extends FieldValues>({
  name,
  label,
  control,
  options,
  ...rest
}: Props<T>) => {
  if (!name) return <></>;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {label && <FormLabel>{label}</FormLabel>}
          <MuiRadioGroup value={value ?? ""} onChange={onChange} {...rest}>
            {options?.map(({ value, label, children }) => (
              <React.Fragment key={value}>
                <FormControlLabel
                  value={value ?? ""}
                  control={<Radio />}
                  label={label}
                />

                {children && <React.Fragment>{children}</React.Fragment>}
              </React.Fragment>
            ))}
          </MuiRadioGroup>
          <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
        </>
      )}
    />
  );
};

export default ControlledRadioGroup;
