import React, { useCallback, useState } from "react";
import { Order } from "services";

function useSort<T>(
  initialValue?: keyof T,
  initialOrder: Order = "asc"
): [keyof T | undefined, Order, any] {
  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(initialValue);

  const handleSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof T) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  return [orderBy, order, handleSort];
}

export default useSort;
