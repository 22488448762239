import { ApplicationType, Role } from "api/models";
import { useAppSelector } from "app";
import { selectApplication } from "features/configuration";
import { selectUser } from "features/user";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

type Props = {
  requiredRoles?: Role[];
  requiredApp?: ApplicationType;
};

const RequireAuth = ({
  requiredRoles,
  requiredApp,
  children,
}: React.PropsWithChildren<Props>) => {
  const user = useAppSelector(selectUser);
  const application = useAppSelector(selectApplication);
  const location = useLocation();

  if (!user || !application) return <></>;

  if (requiredRoles && !requiredRoles?.includes(user!.role)) {
    return (
      <Navigate
        to="/unauthorized"
        replace
        state={{ path: location.pathname }}
      />
    );
  }

  if (requiredApp && application && requiredApp !== application) {
    if (application === ApplicationType.QccSales)
      return <Navigate to="/energy/new" replace />;
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default RequireAuth;
