import {
  EnergyComparison,
  EnergyComparisonFilter,
  EnergyComparisonOverview,
  EnergyComparisonOverviewFilter,
  EnergyStep,
  ListFilter,
} from "api/models";
import utils from "api/utils";

const baseUrl = "api/energyComparison";

const get = async (filter?: EnergyComparisonOverviewFilter) => {
  return utils.apiGet<EnergyComparisonOverview>(baseUrl, filter);
};

const getById = async (id: number) => {
  return utils.apiGet<EnergyComparison>(`${baseUrl}/${id}`);
};

const getFilters = async () => {
  return utils.apiGet<EnergyComparisonFilter>(`${baseUrl}/filters`);
};

const getListFilters = async () => {
  return utils.apiGet<ListFilter>(`${baseUrl}/listfilters`);
};

const upsert = async (comparison: EnergyComparison) => {
  return utils.apiCommand<EnergyComparison, number>(
    baseUrl,
    comparison,
    "POST"
  );
};

const updateStep = async (id: number, step: EnergyStep) => {
  return utils.apiCommand(`${baseUrl}/step/${id}`, step, "PUT");
};

const copy = async (id: number) => {
  return utils.apiCommand(`${baseUrl}/copy`, {comparisonId: id}, "POST");
}

const remove = async (id: number) => {
  return utils.apiCommand(`${baseUrl}/${id}`, null, "DELETE");
};

const comparisonService = {
  get,
  getById,
  getFilters,
  getListFilters,
  upsert,
  updateStep,
  copy,
  remove,
};

export default comparisonService;
