import { useCallback, useState } from "react";

const useStepper = (initialState = 0): [number, () => void, () => void] => {
  const [state, setState] = useState<number>(initialState);

  const goBack = useCallback(
    () => setState((state) => (state > 0 ? state - 1 : state)),
    []
  );

  const goNext = useCallback(() => setState((state) => state + 1), []);

  return [state, goBack, goNext];
};

export default useStepper;
