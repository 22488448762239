import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { User, Country, Product, ProductDetail } from "api/models";
import { RootState } from "app";
import { sortService } from "services";

const selectSelf = (state: RootState) => state.configuration;
export const selectApplication = createDraftSafeSelector(
  selectSelf,
  (state) => state.application
);

export const selectApplicationSwitch = createDraftSafeSelector(
  (state: RootState) => state,
  (state) =>
    state.user.profile?.applications.filter(
      (a) => a.app !== state.configuration.application
    )
);

export const selectUsersFiltered = (
  filters: {
    property: (keyof User)[];
    value: string | null;
    equals?: boolean;
  }[],
  comparer: (a: User, b: User) => number,
  page: number,
  rowsPerPage: number
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!state.users) return [];

    const filteredUsers = state.users.filter((u) =>
      filters.every((f) =>
        !f.value
          ? true
          : f.property.some((prop) =>
              f.equals
                ? u[prop].toString().toLowerCase() === f.value!.toLowerCase()
                : u[prop]
                    .toString()
                    .toLowerCase()
                    .includes(f.value!.toLowerCase())
            )
      )
    );
    return sortService
      .sort(filteredUsers, comparer)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((u) => u.id);
  });

export const selectUserCount = (
  filters: {
    property: (keyof User)[];
    value: string | null;
    equals?: boolean;
  }[]
) =>
  createDraftSafeSelector(
    selectSelf,
    (state) =>
      state.users?.filter((u) =>
        filters.every((f) =>
          !f.value
            ? true
            : f.property.some((prop) =>
                f.equals
                  ? u[prop].toString().toLowerCase() === f.value!.toLowerCase()
                  : u[prop]
                      .toString()
                      .toLowerCase()
                      .includes(f.value!.toLowerCase())
              )
        )
      ).length
  );

export const selectUserById = (userId: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.users?.find((u) => u.id === userId)
  );

export const selectCountries = createDraftSafeSelector(selectSelf, (state) =>
  state.countries?.map((c) => c.code)
);

export const selectCountriesFiltered = (
  filter: string | null,
  comparer: (a: Country, b: Country) => number,
  page: number,
  rowsPerPage: number
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!state.countries) return [];

    var filteredCountries = state.countries.filter(
      (c) =>
        !filter ||
        c.code?.toUpperCase() === filter.toUpperCase() ||
        c.name?.toUpperCase().includes(filter.toUpperCase())
    );
    return sortService
      .sort(filteredCountries, comparer)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((u) => u.code);
  });

export const selectCountryByCode = (code?: string) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.countries?.find((c) => c.code === code)
  );

export const selectCountryCount = (filter: string | null) =>
  createDraftSafeSelector(
    selectSelf,
    (state) =>
      state.countries?.filter(
        (c) =>
          !filter ||
          c.code?.toUpperCase() === filter.toUpperCase() ||
          c.name?.toUpperCase().includes(filter.toUpperCase())
      ).length
  );
export const selectCountryFilters = createDraftSafeSelector(
  selectSelf,
  (state) => state.countryFilters
);
export const selectBrands = createDraftSafeSelector(
  selectSelf,
  (state) =>
    state.brands &&
    [...state.brands].sort(
      (a, b) => Number(a.competitor) - Number(b.competitor) // Axalta brands always on top
    )
);

export const selectProductsFiltered = (
  comparer: (a: Product, b: Product) => number,
  page: number,
  rowsPerPage: number
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!state.products) return [];

    var products = [...state.products];
    return sortService
      .sort(products, comparer)
      .map((p) => p.id)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  });

export const selectProductCount = createDraftSafeSelector(
  selectSelf,
  (state) => state.products?.length
);

export const selectProductById = (id: string) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.products?.find((p) => p.id === id)
  );

export const selectProductDetailsFiltered = (
  comparer: (a: ProductDetail, b: ProductDetail) => number,
  page: number,
  rowsPerPage: number
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!state.productDetails) return [];

    var products = [...state.productDetails];
    return sortService
      .sort(products, comparer)
      .map((p) => p.id)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  });

export const selectProductDetailsCount = createDraftSafeSelector(
  selectSelf,
  (state) => state.productDetails?.length
);

export const selectProductDetailById = (id: string) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.productDetails?.find((p) => p.id === id)
  );
