import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { PrintContext } from "shared/context";

import { GraphProps } from "./models";

const LineGraph = ({ title, data, formatter }: GraphProps) => {
  const print = useContext(PrintContext);

  const t9n = useAppSelector(selectT9N);

  if (!data) return <></>;

  return (
    <Chart
      type="line"
      options={{
        chart: {
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              pan: false,
              download: !print,
            },
          },
          animations: {
            enabled: !print,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        yaxis: {
          labels: {
            formatter,
          },
        },
        noData: {
          text: t9n?.NoData,
        },
        title: {
          text: title,
        },
        legend: {
          onItemClick: {
            toggleDataSeries: !print,
          },
          onItemHover: {
            highlightDataSeries: !print,
          },
        },
        tooltip: {
          enabled: !print,
        },
      }}
      series={data.map((d) => ({
        name: d.name,
        data: d.values.map((v) => ({ x: v.x, y: v.y })),
      }))}
    />
  );
};

export default LineGraph;
