import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  Forecast,
  ForecastCriteria,
  Comparison,
  ComparisonFilter,
  Layer,
  Mode,
  ProductType,
  Step,
  StepMode,
  ListFilter,
} from "api/models";
import { comparisonService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";
import { dateService } from "services";

export type ComparisonState = {
  filters?: ComparisonFilter;
  listFilters?: ListFilter;
  comparison?: Comparison | null;
  steps?: Step[];
  forecastCriteria: ForecastCriteria;
  forecast?: Forecast;
};

const initialState: ComparisonState = {
  forecastCriteria: {
    carsPerWeek: 25,
    workingWeeksPerYear: 48,
    averageRevenuePerDamage: 100,
  },
};

export const getComparisonById = createAsyncThunk(
  "get/comparison/id",
  async (arg: { id: number; archived?: boolean }, thunkAPI) => {
    return await comparisonService.getById(arg.id, arg.archived);
  }
);

export const getStepById = createAsyncThunk(
  "get/step/id",
  async (id: number, thunkAPI) => {
    return await comparisonService.getStepById(id);
  }
);

export const getFilters = createAsyncThunk(
  "get/comparison/filters",
  async (arg, thunkAPI) => {
    return await comparisonService.getFilters();
  }
);

export const getListFilters = createAsyncThunk(
  "get/compare/filter/list",
  async (_, thunkAPI) => {
    return await comparisonService.getListFilters();
  }
);

export const getForecast = createAsyncThunk(
  "get/comparison/annualforecast",
  async (arg: ForecastCriteria, thunkAPI) => {
    return await comparisonService.getForecast(arg);
  }
);

export const upsertComparison = createAsyncThunk(
  "upsert/comparison",
  async (arg: Comparison, { dispatch, rejectWithValue }) => {
    try {
      const id = await comparisonService.upsert(arg);
      if (id && id > 0) dispatch(getComparisonById({ id, archived: false }));
    } catch (error) {
      dispatch(
        setMessage({
          message: "ComparisonSaveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const updateStep = createAsyncThunk(
  "update/step",
  async (
    arg: { id: number; comparisonId: number; step: Step },
    { dispatch, rejectWithValue }
  ) => {
    const { id, comparisonId, step } = arg;
    try {
      await comparisonService.updateStep(id, step);

      if (id && id > 0) {
        dispatch(getComparisonById({ id: comparisonId, archived: false }));
      }

      return step;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ComparisonSaveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

const comparisonSlice = createSlice({
  name: "comparison",
  initialState,
  reducers: {
    setForecastCriteria: (state, action: PayloadAction<ForecastCriteria>) => {
      const { comparisonId, ...criteria } = action.payload;
      state.forecastCriteria = criteria;
    },
    clearComparison: (state) => {
      state.comparison = undefined;
      state.steps = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFilters.fulfilled,
      (state, action: PayloadAction<ComparisonFilter | null>) => {
        if (action.payload) state.filters = action.payload;
      }
    );
    builder.addCase(
      getListFilters.fulfilled,
      (state, action: PayloadAction<ListFilter | null>) => {
        state.listFilters = action.payload ?? undefined;
      }
    );
    builder.addCase(
      getComparisonById.fulfilled,
      (state, action: PayloadAction<Comparison | null>) => {
        state.comparison = action.payload
          ? {
              ...action.payload,
              date: dateService.toDateString(new Date(action.payload.date!)),
              steps: undefined,
            }
          : null;
        state.steps = action.payload?.steps;

        //Add the current axalta brand to current system
        const axaltaBrand = state.filters?.brands.find(
          (b) => b.id === state.comparison?.brandId
        );
        if (
          axaltaBrand &&
          state.filters &&
          !state.filters.competitorBrands.find((cb) => cb.id === axaltaBrand.id)
        )
          state.filters.competitorBrands = [
            axaltaBrand,
            ...state.filters.competitorBrands.filter((b) => b.competitor),
          ];
      }
    );
    builder.addCase(
      getStepById.fulfilled,
      (state, action: PayloadAction<Step | null>) => {
        updateStateStep(state, action.payload);
      }
    );
    builder.addCase(
      getForecast.fulfilled,
      (state, action: PayloadAction<Forecast | null>) => {
        if (action.payload) {
          state.forecast = action.payload;
        }
      }
    );
    builder.addCase(
      updateStep.fulfilled,
      (state, action: PayloadAction<Step | undefined>) => {
        updateStateStep(state, action.payload);
      }
    );
  },
});

const selectSelf = (state: RootState) => state.comparison;
export const selectFilters = createDraftSafeSelector(
  selectSelf,
  (state) => state.filters
);
export const selectListFilters = createDraftSafeSelector(
  selectSelf,
  (state) => {
    let defaultCurrency: string | undefined = undefined;
    const currencies = state.listFilters?.currencies;
    if (currencies && currencies.length > 1) {
      defaultCurrency = "EUR";
      if (!currencies?.some((c) => c === "EUR"))
        defaultCurrency = currencies[0];
    }
    return { ...state.listFilters, defaultCurrency };
  }
);
export const selectConsumptionFactor = (productType?: ProductType) =>
  createDraftSafeSelector(selectSelf, (state) => {
    switch (productType) {
      case ProductType.Clearcoat:
        return state.filters?.ccFactor;
      case ProductType.Basecoat:
        return state.filters?.bcFactor;
      default:
        return state.filters?.ucFactor;
    }
  });
export const selectComparison = createDraftSafeSelector(
  selectSelf,
  (state) => state.comparison
);
export const selectCurrency = createDraftSafeSelector(
  selectSelf,
  (state) => state.comparison?.currency
);
export const selectSteps = createDraftSafeSelector(
  selectSelf,
  (state) => state.steps
);
export const selectStepIds = createDraftSafeSelector(selectSelf, (state) =>
  state.steps?.map((s) => s.id)
);
export const selectStepById = (id?: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    id ? state.steps?.find((s) => s.id === id) : undefined
  );
export const selectAxaltaBrand = createDraftSafeSelector(
  selectSelf,
  (state) => ({
    id: state.comparison?.brandId,
    name: state.comparison?.brand,
  })
);
export const selectApplicationMode = (id?: number, productType?: ProductType) =>
  createDraftSafeSelector(
    selectSelf,
    (state) =>
      productType &&
      state.filters?.applicationModes[productType].find((am) => am.id === id)
  );
export const selectFlashMode = (id?: number, layer?: Layer) =>
  createDraftSafeSelector(selectSelf, (state) =>
    (layer
      ? state.filters?.flashModes?.[layer]
      : state.filters?.flashModes?.[Layer.NoCoat]
    )?.find((fm) => fm.id === id)
  );

export const selectFlashModes = (
  layer?: Layer,
  availableModes: StepMode[] = []
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    return (
      (layer
        ? state.filters?.flashModes?.[layer]
        : state.filters?.flashModes?.[Layer.NoCoat]) ??
      state.filters?.flashModes?.[Layer.NoCoat]
    )?.filter((dm) => {
      var modes = availableModes?.filter((x) => x.mode === Mode.Flash);
      return modes && modes.length > 0
        ? modes?.some((m) => m.id === dm.id)
        : true;
    });
  });

export const selectDryingModes = (availableModes: StepMode[] = []) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.filters?.dryingModes.filter((dm) => {
      var modes = availableModes?.filter((x) => x.mode === Mode.Drying);
      return modes && modes.length > 0
        ? modes?.some((m) => m.id === dm.id)
        : true;
    })
  );
export const selectForecastCriteria = createDraftSafeSelector(
  selectSelf,
  (state) => state.forecastCriteria
);
export const selectForecast = createDraftSafeSelector(
  selectSelf,
  (state) => state.forecast
);

function updateStateStep(state: Draft<ComparisonState>, step?: Step | null) {
  const stateStep = state.steps?.find((c) => c.id === step?.id);

  if (stateStep) {
    stateStep.product = step?.product;
    stateStep.productCode = step?.productCode;
    stateStep.activatorThinner = step?.activatorThinner;
    stateStep.price = step?.price;
    stateStep.quantity = step?.quantity;
    stateStep.quantityGrams = step?.quantityGrams;
    stateStep.applicationMode = step?.applicationMode;
    stateStep.applicationTimeInSec = step?.applicationTimeInSec;
    stateStep.tintedClearDescription = step?.tintedClearDescription;
    stateStep.intermediateFlashMode = step?.intermediateFlashMode;
    stateStep.finalFlashMode = step?.finalFlashMode;
    stateStep.flashTimeInSec = step?.flashTimeInSec;
    stateStep.dryingMode = step?.dryingMode;
    stateStep.dryingTimeInSec = step?.dryingTimeInSec;
    stateStep.competitorBrand = step?.competitorBrand;
    stateStep.competitorProduct = step?.competitorProduct;
    stateStep.competitorProductCode = step?.competitorProductCode;
    stateStep.competitorActivatorThinner = step?.competitorActivatorThinner;
    stateStep.competitorPrice = step?.competitorPrice;
    stateStep.competitorQuantity = step?.competitorQuantity;
    stateStep.competitorQuantityGrams = step?.competitorQuantityGrams;
    stateStep.competitorApplicationMode = step?.competitorApplicationMode;
    stateStep.competitorApplicationTimeInSec = step?.competitorApplicationTimeInSec;
    stateStep.competitorTintedClearDescription =step?.competitorTintedClearDescription;
    stateStep.competitorIntermediateFlashMode = step?.competitorIntermediateFlashMode;
    stateStep.competitorFinalFlashMode = step?.competitorFinalFlashMode;
    stateStep.competitorFlashTimeInSec = step?.competitorFlashTimeInSec;
    stateStep.competitorDryingMode = step?.competitorDryingMode;
    stateStep.competitorDryingTimeInSec = step?.competitorDryingTimeInSec;
  }
}

export const { setForecastCriteria, clearComparison } = comparisonSlice.actions;
export default comparisonSlice.reducer;
