import { Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app";
import { selectT9N } from "features/locale";
import { clearMessage, selectMessage } from "features/message";
import React, { useEffect } from "react";

let timeOutId: any;
const Message = () => {
  const t9n = useAppSelector(selectT9N);
  const { message, status, timeOut } = useAppSelector(selectMessage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (timeOut)
      timeOutId = setTimeout(() => dispatch(clearMessage()), timeOut);
    return () => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [dispatch, timeOut]);
  return (
    <>
      {message ? (
        <>
          <Alert
            severity={status}
            onClick={() => dispatch(clearMessage())}
            onClose={() => dispatch(clearMessage())}
            sx={{ my: 2, cursor: "pointer" }}
          >
            {t9n?.[message] ?? message}
          </Alert>
        </>
      ) : null}
    </>
  );
};

export default Message;
