import { Box, Container } from "@mui/material";
import Message from "features/message/Message";
import React from "react";

import Breadcrumbs from "./Breadcrumbs";
import Header from "./Header";
import Navbar from "./NavBar";

const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <Box m={0} p={0} component="main" display="flex">
        <Box displayPrint="none">
          <Navbar />
        </Box>

        <Box
          id="maincontent"
          sx={{ flexGrow: 1, maxWidth: "calc(100% - 6.5rem)" }}
        >
          <Box displayPrint="none">
            <Header />
          </Box>

          <Container maxWidth="xl" sx={{ mt: 2 }}>
            <Box displayPrint="none">
              <Breadcrumbs />
              <Message />
            </Box>

            <Box my={3}>{children}</Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
