import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React from "react";
import { useToggle } from "shared/hooks";

interface ShowMoreProps {
  list: string[];
  hideBorder?: boolean;
}
const ShowMore = ({ list, hideBorder }: ShowMoreProps) => {
  const [showMore, toggleShowMore] = useToggle();
  const maxShowMoreCount = 5;

  const t9n = useAppSelector(selectT9N);

  return list.length > 0 ? (
    <Box
      sx={{
        border: !hideBorder ? "1px solid" : null,
        borderColor: "divider",
        borderRadius: 2,
        p: 1,
      }}
    >
      {list
        .filter((x, i) => (!showMore ? i < maxShowMoreCount : true))
        .map((x, i) => {
          return <Typography key={i}>{t9n?.[x] ?? x}</Typography>;
        })}
      {(list.length ?? 0) > maxShowMoreCount ? (
        <Link
          onClick={toggleShowMore}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
          }}
        >
          {showMore ? t9n?.ShowLess : t9n?.ShowMore}
        </Link>
      ) : null}
    </Box>
  ) : null;
};

export default ShowMore;
