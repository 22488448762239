import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import messageReducer from "features/message/slice";
import i18nReducer from "features/locale/slice";
import userReducer from "features/user/slice";
import configurationReducer from "features/configuration/slice";
import productReducer from "features/product/slice";
import comparisonReducer from "features/comparison/slice";
import energyComparisonReducer from "features/energyComparison/slice";
import archiveReducer from "features/archive/slice";
import energyArchiveReducer from "features/energyArchive/slice";
import compareReducer from "features/compare/slice";

export const store = configureStore({
  reducer: {
    message: messageReducer,
    i18n: i18nReducer,
    user: userReducer,
    configuration: configurationReducer,
    product: productReducer,
    comparison: comparisonReducer,
    energyComparison: energyComparisonReducer,
    archive: archiveReducer,
    energyArchive: energyArchiveReducer,
    compare: compareReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
