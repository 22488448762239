import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { EnergyProductType, ProductRequest, ProductsByBrand, ProductType } from "api/models";
import { productService } from "api/services";
import { RootState } from "app";

export type ProductState = {
  products: ProductsByBrand;
};

const initialState: ProductState = {
  products: {},
};

export const getProducts = createAsyncThunk(
  "get/products",
  async (arg: ProductRequest, thunkAPI) => {
    var response = await productService.getProducts(arg);
    if (response) return { brandId: arg.brandId, products: response };
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearProducts: (state) => {
      state.products = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      if (action.payload)
        state.products[action.payload.brandId] = action.payload.products;
    });
  },
});

const selectSelf = (state: RootState) => state.product;
export const selectProductsByBrandId = (brandId?: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    brandId ? state.products[brandId] : undefined
  );

type SelectProductsCriteria = {
  brandId?: number;
  productType?: ProductType | EnergyProductType;
  processCode?: string;
  productCode?: string;
};

export const selectProducts = (criteria?: SelectProductsCriteria) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!criteria || !criteria.brandId || !criteria.productType) return [];

    if (criteria.productType === EnergyProductType.Preparation)
      criteria.productType = ProductType.Undercoat;

    const productsByType =
      state.products[criteria.brandId]?.[criteria.productType] ?? {};

    if (criteria.processCode)
      return productsByType?.[criteria.processCode];

    return Object.keys(productsByType ?? {}).flatMap(
      (processCode) => productsByType[processCode]);
  });

export const selectProductDetail = (criteria: SelectProductsCriteria) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!criteria.brandId || !criteria.productType) return undefined;

    if (criteria.productType === EnergyProductType.Preparation)
      criteria.productType = ProductType.Undercoat;

    const productsByType =
      state.products[criteria.brandId]?.[criteria.productType] ?? {};

    if (criteria.processCode)
      return productsByType?.[criteria.processCode]?.find(p => p.productCode === criteria.productCode)

    return Object.keys(productsByType ?? {}).flatMap(
      (processCode) => productsByType[processCode]
    )?.find(p => p.productCode === criteria.productCode);
  });

export const selectProductUniqueActivatorThinnerList = (criteria: SelectProductsCriteria) =>
  createDraftSafeSelector(selectSelf, (state) => {
    if (!criteria.brandId || !criteria.productType) return undefined;

    if (criteria.productType === EnergyProductType.Preparation)
      criteria.productType = ProductType.Undercoat;

    const productsByType =
      state.products[criteria.brandId]?.[criteria.productType] ?? {};

    var activatorThinners =  Object.keys(productsByType ?? {}).flatMap(
      (processCode) => productsByType[processCode])
      ?.filter(p => p.productCode === criteria.productCode)
      ?.flatMap(p => p.processes
        ?.filter(p => !!p.activatorThinner)
        ?.map(pp => pp.activatorThinner ));
    //Filter unique values 
    return activatorThinners.filter((value, index, array) => array.indexOf(value) === index);
  });
export const selectProductBrands =
  createDraftSafeSelector(selectSelf, (state) => Object.keys(state.products));

export const { clearProducts } = productSlice.actions;
export default productSlice.reducer;
