import { format as dateFormat, isDate, parse } from "date-fns";

const parseDateString = (value: string) => {
  return isDate(value) ? value : parse(value, "yyyy-MM-dd", new Date());
};

const toDateString = (value: Date, format: string = "yyyy-MM-dd") => {
  return dateFormat(value, format);
};

const toTimeString = (seconds?: number) => {
  if (seconds) {
    const prefix = seconds < 0 ? "-" : "";
    seconds = Math.abs(seconds);

    let hours: number | string = Math.floor(seconds / 3600);
    let minutes: number | string = Math.floor((seconds - hours * 3600) / 60);
    let secs: number | string = seconds - hours * 3600 - minutes * 60;
      
    if (hours > 0) return `${prefix}${hours} hours ${minutes} min ${secs} sec`;
    if (minutes > 0) return `${prefix}${minutes} min ${secs} sec`;
    return secs + " sec";
  }
  return "";
};

const toHHMMSS = (seconds?: number) => {
  if (seconds) {
    const prefix = seconds < 0 ? "-" : "";
    seconds = Math.abs(seconds);

    let hours: number | string = Math.floor(seconds / 3600);
    let minutes: number | string = Math.floor((seconds - hours * 3600) / 60);
    let secs: number | string = seconds - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (secs < 10) {
      secs = "0" + secs;
    }
    return prefix + hours + ":" + minutes + ":" + secs;
  }
  return "";
};

const dateService = { parseDateString, toDateString, toTimeString, toHHMMSS };

export default dateService;
