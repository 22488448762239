import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Brand } from "api/models";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Dialog, Image } from "shared/components";

import { FormProps } from "./form.props";

type Props<T> = FormProps<T> & {
  brands?: Brand[];
};

const BrandSelection = <T extends FieldValues>({
  name,
  brands,
  setValue,
  watch,
  trigger,
}: Props<T>) => {
  const selectedId = watch && name && watch(name);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectDialogBrand, setSelectDialogBrand] = useState(
    selectedId ? +selectedId : undefined
  );

  const activeBrands: Brand[] | undefined = brands?.filter((b) => b.active);

  const t9n = useAppSelector(selectT9N);
  const selectedBrand = brands?.find((b) => b.id === selectedId);

  // Set brandId when only 1 brand is active
  useEffect(() => {
    if (name && activeBrands?.length === 1 && !selectedId) {
      setValue && setValue(name, activeBrands[0].id as any);
    }
  }, [setValue, selectedId, activeBrands, name]);

  if (!name) return <></>;

  return (
    <>
      {activeBrands &&
      (activeBrands?.length > 1 ||
        (selectedId && activeBrands?.some((ab) => ab.id !== selectedId))) ? (
        <Button
          fullWidth
          variant="outlined"
          sx={{
            backgroundColor: "secondary.light!important",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            textTransform: "initial",
            my: 1,
          }}
          disabled={!Array.isArray(brands) || !brands.length}
          onClick={() => setOpenDialog(true)}
        >
          <Box
            display="flex"
            sx={{
              "& img": {
                filter: (theme: any) => theme.palette.primary.filter.main,
              },
            }}
          >
            <Image fileName="add.svg" alt="Add" />
            <Typography sx={{ pl: 2 }}>{t9n?.AxaltaBrand} *</Typography>
          </Box>

          <Typography fontWeight="bold">{selectedBrand?.name}</Typography>
        </Button>
      ) : (
        <Box display="flex" my={2}>
          <Typography>{t9n?.AxaltaBrand}: </Typography>
          <Typography fontWeight="bold" color="primary" pl={2}>
            {selectedBrand?.name}
          </Typography>
        </Box>
      )}

      <Dialog
        title={t9n?.AddAxaltaBrand}
        open={openDialog}
        confirmTitle={t9n?.Save}
        cancelTitle={t9n?.Cancel}
        close={() => setOpenDialog(false)}
        fullWidth
      >
        <Grid container spacing={2}>
          {activeBrands?.map((b) => (
            <Grid key={b.id} item xs={6} sm={4} xl={3}>
              <Paper
                variant="outlined"
                sx={{
                  width: "100%",
                  height: "100%",
                  minHeight: "8rem",
                  borderWidth: selectDialogBrand === b.id ? "2px" : "1px",
                  borderColor:
                    selectDialogBrand === b.id ? "primary.main" : "divider",
                }}
              >
                <Button
                  onClick={() => {
                    setSelectDialogBrand(b.id);
                    if (setValue) {
                      setValue(name, b.id as any);
                      trigger && trigger(name);
                      setOpenDialog(false);
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    "& img": {
                      p: 1,
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" height="75px">
                    <Image
                      fileName={`${b.name.toLowerCase()}.png`}
                      alt={b.name}
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    color="primary.light"
                    sx={{
                      fontWeight: "bold",
                      maxWidth: "7.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {b.name}
                  </Typography>
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </>
  );
};

export default BrandSelection;
