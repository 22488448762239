import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app";
import { selectLanguages } from "features/locale";
import { selectLanguage, setLanguage } from "features/user";
import React from "react";
import { Image } from "shared/components";
import { useMenuToggle } from "shared/hooks";

const LocalizationSelection = () => {
  const [menuAnchorEl, menuOpen, handleMenuClick, handleMenuClose] =
    useMenuToggle();

  const languages = useAppSelector(selectLanguages);
  let currentLanguage = useAppSelector(selectLanguage);

  const dispatch = useAppDispatch();
  const handleLanguageChosen = (language?: string) => {
    handleMenuClose();
    if (language && languages[language]) {
      dispatch(setLanguage(language));
    }
  };

  if (!currentLanguage) return <></>;

  return (
    <Box>
      <Button
        disableRipple
        onClick={handleMenuClick}
        sx={{
          p: 0,
          justifyContent: "flex-start",
          "& > img": {
            filter: (theme: any) => theme.palette.secondary.filter?.main,
          },
        }}
      >
        <Typography variant="subtitle2" color="secondary">
          {languages[currentLanguage]}
        </Typography>

        <Image
          fileName="menu.svg"
          alt="menu"
          className={menuOpen ? "turnImg" : ""}
        />
      </Button>
      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
        {Object.entries(languages).map(([key, value]) => (
          <MenuItem key={key} onClick={() => handleLanguageChosen(key)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LocalizationSelection;
