import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React from "react";
import Chart from "react-apexcharts";

import { GraphProps } from "./models";

const GroupedColumnGraph = ({
  title,
  data,
  formatter,
  totalFormatter,
}: GraphProps) => {
  const t9n = useAppSelector(selectT9N);

  if (!data) return <></>;

  return (
    <Chart
      type="bar"
      height={500}
      options={{
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter,
        },
        yaxis: {
          labels: {
            formatter,
          },
        },
        noData: {
          text: t9n?.NoData,
        },
        title: {
          text: title,
        },
      }}
      series={data.map((d) => ({
        name: d.name,
        data: d.values.map((v) => ({ x: v.x, y: v.y })),
      }))}
    />
  );
};

export default GroupedColumnGraph;
