import { BaseSelectProps, MenuItem } from '@mui/material';
import React from 'react';
import { FieldValues } from 'react-hook-form';

import ControlledSelect from './ControlledSelect';
import { FormProps } from './form.props';

type Props<T> = FormProps<T> &
BaseSelectProps & {
    currencies?: string[];
  };

const Currencies = [
  "AMD",
  "AZN",
  "BAM",
  "BGN",
  "BYR",
  "CHF",
  "CZK",
  "DKK",
  "EEK",
  "EUR",
  "GBP",
  "GEL",
  "HRK",
  "HUF",
  "ISK",
  "LTL",
  "LVL",
  "MDL",
  "MKD",
  "NOK",
  "PLN",
  "RON",
  "RSD",
  "RUB",
  "SEK",
  "SKK",
  "TRY",
  "UAH",
]

const ControlledCurrency = <T extends FieldValues>({
  name,
  control,
  currencies,
  ...rest
}: Props<T>) => {
  if (!name) return <></>;

  return (
    <ControlledSelect name={name} control={control} {...rest}>
      {(currencies && currencies.length > 0 ? currencies : Currencies)
        .map((r: string) => (
          <MenuItem key={r} value={r}>
            {r}
          </MenuItem>
        ))
      }
    </ControlledSelect>
  );
};

export default ControlledCurrency;
