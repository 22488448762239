import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { FormProps } from "./form.props";

type Props<T> = FormProps<T> & TextFieldProps;

export const ControlledTextField = <T extends FieldValues>({
  name,
  control,
  onBlur: onCustomBlur,
  helperText,
  ...rest
}: Props<T>) => {
  if (!name) return <></>;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <MuiTextField
          value={value ?? ""}
          onChange={onChange}
          error={Boolean(error?.message)}
          {...rest}
          onBlur={(e) => {
            onBlur();
            onCustomBlur && onCustomBlur(e);
          }}
          helperText={helperText || error?.message}
        />
      )}
    />
  );
};

export default ControlledTextField;
