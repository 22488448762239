import { useTheme } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { PrintContext } from "shared/context";

import { GraphProps } from "./models";

const AreaGraph = ({ title, data, formatter }: GraphProps) => {
  const print = useContext(PrintContext);

  const theme = useTheme();
  const [colors, setColors] = useState<string[]>([theme.palette.success.main]);
  const t9n = useAppSelector(selectT9N);

  useEffect(() => {
    setColors(
      data.map((d) =>
        (d.values[0]?.y ?? 0) >= 0
          ? theme.palette.success.main
          : theme.palette.error.main
      )
    );
  }, [data, theme]);

  if (!data) return <></>;

  return (
    <Chart
      type="area"
      options={{
        chart: {
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              pan: false,
              download: !print,
            },
          },
          animations: {
            enabled: !print,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        stroke: {
          curve: "straight",
        },
        yaxis: {
          labels: {
            formatter,
          },
        },
        noData: {
          text: t9n?.NoData,
        },
        title: {
          text: title,
        },
        colors: colors,
        legend: {
          onItemClick: {
            toggleDataSeries: !print,
          },
          onItemHover: {
            highlightDataSeries: !print,
          },
        },
        tooltip: {
          enabled: !print,
        },
      }}
      series={data.map((d) => ({
        name: d.name,
        data: d.values.map((v) => ({ x: v.x, y: v.y })),
      }))}
    />
  );
};

export default AreaGraph;
