import "./Upload.css";

import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CsvProcessResult, UploadStatus } from "api/models";
import { useAppDispatch, useAppSelector } from "app";
import { selectT9N } from "features/locale";
import { setMessage } from "features/message";
import React, { useState } from "react";
import { ShowMore } from "shared/components";

import Download from "./Download";
import DropZone from "./Dropzone";

type State = {
  file?: File;
  uploadStatus?: UploadStatus;
  uploadProgress?: number;
};

type Props = {
  downloadTitle?: string;
  download: () => void;
  upload: (
    file: Blob | File,
    onUploadProgress: (
      progress: number,
      status: UploadStatus,
      result?: CsvProcessResult
    ) => void
  ) => void;
  onDone: () => void;
  onError: () => void;
};

const Upload = ({
  downloadTitle,
  download,
  upload,
  onDone,
  onError,
}: Props) => {
  const [state, setState] = useState<State>({});
  const [resultList, setResultList] = useState<string[]>([]);

  const t9n = useAppSelector(selectT9N);

  const dispatch = useAppDispatch();

  const onFilesAdded = (file: File) => {
    setState({ ...state, file });
  };

  const handleResultOnDone = (result?: CsvProcessResult) => {
    setResultList([]);

    if (!result) return;

    if(result.error) {
      dispatch(setMessage({ message: result.error, status: "error" }));
      return;
    }
      
    if (result.processFails?.length > 0) {
      setResultList([...result.processFails.map(f =>`${t9n?.ProductCode} ${f.productCode} (${t9n?.Column} ${f.column}): ${t9n?.[f.message]?.format(f.additionalInfo)}`)]);
      dispatch(setMessage({ message: "UploadWarning", status: "warning" }));
    } else {
      setResultList([
        `${t9n?.ProcessedSuccess}: ${
          result.processedAmount
        } ${t9n?.Products?.toLocaleLowerCase()}`,
      ]);
      dispatch(
        setMessage({
          message: "UploadSuccess",
          status: "success",
          timeOut: 5000,
        })
      );
    }
  };

  const uploadFiles = () => {
    setState({
      ...state,
      uploadProgress: 0,
      uploadStatus: UploadStatus.Pending,
    });

    if (state.file)
      upload(state.file, (progress, status, result) => {
        setState({
          ...state,
          uploadProgress: progress,
          uploadStatus: status,
        });
        if (status === UploadStatus.Error && progress === -1) {
          onError();
        }
        if (status === UploadStatus.Done && progress === -1) {
          onDone();
          handleResultOnDone(result);
        }
      });
  };

  return (
    <>
      <Download title={downloadTitle} download={download} />

      <DropZone
        disabled={state.uploadStatus === UploadStatus.Pending}
        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onFileAdded={onFilesAdded}
      />

      {state.file ? <Typography>{state.file.name}</Typography> : null}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap-reverse",
          my: 1,
        }}
      >
        <Button
          variant="contained"
          disabled={!state.file || state.uploadStatus === UploadStatus.Pending}
          onClick={uploadFiles}
        >
          {t9n?.Upload}
        </Button>

        {state.file ? (
          <Box className="progress-bar" bgcolor="secondary.main">
            <Box
              sx={{
                width: `${state.uploadProgress ?? 0}%`,
                backgroundColor:
                  state.uploadStatus === UploadStatus.Error
                    ? "error.light"
                    : "success.main",
              }}
            ></Box>
          </Box>
        ) : null}
      </Box>

      <ShowMore list={resultList} />
    </>
  );
};

export default Upload;
