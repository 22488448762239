import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { useAppSelector } from "app";
import { ActiveRoutePath, useActiveRoutePaths } from "app/routing";
import { selectT9N } from "features/locale";
import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumbs = () => {
  const t9n = useAppSelector(selectT9N);
  const activeRoutePaths: ActiveRoutePath[] = useActiveRoutePaths();
  const lastRoutePath = activeRoutePaths.slice(-1)[0];

  if (
    !t9n ||
    activeRoutePaths.length <= 1 ||
    activeRoutePaths.some((arp) => arp.definition.use?.hideBreadCrumb)
  )
    return <></>;

  return (
    <MuiBreadcrumbs>
      {activeRoutePaths
        .filter((arp) => arp.title || arp.alias)
        .map((active, index, { length }) => {
          if (index !== length - 1 && active.definition.element) {
            let link = active.match.pathname;
            const nextRoutePath = activeRoutePaths[index + 1].definition.path;
            if (
              nextRoutePath.startsWith(":") &&
              lastRoutePath.match.params[nextRoutePath.substring(1)]
            ) {
              link += `/${
                lastRoutePath.match.params[nextRoutePath.substring(1)]
              }`;
            }
            return (
              <Link key={active.definition.path} component={NavLink} to={link}>
                <Typography color="primary.light">
                  {t9n[active.definition.alias || active.title]}
                </Typography>
              </Link>
            );
          }

          return (
            <Link
              key={active.definition.path}
              underline="none"
              color="secondary"
            >
              {t9n[active.definition.alias || active.title]}
            </Link>
          );
        })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
