import { Typography } from "@mui/material";
import React from "react";
import { useImage } from "shared/hooks";

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  fileName: string;
};

const Image = ({ fileName, alt, className, ...rest }: Props) => {
  const { loading, error, image } = useImage(fileName);

  if (!image) return <></>;
  if (error) return <Typography>{alt}</Typography>;

  return (
    <>
      {loading ? (
        <Typography>Loading</Typography>
      ) : (
        <img
          className={`Image${
            className ? className.padStart(className.length + 1) : ""
          }`}
          src={image}
          alt={alt}
          {...rest}
        />
      )}
    </>
  );
};

export default Image;
