import { Pagination, Stack, Theme, useMediaQuery } from "@mui/material";
import React, { ChangeEvent } from "react";

type Props = {
  page: number;
  take: number;
  totalCount: number;
  setPage: (skip: number) => void;
};

const Paging = ({ page, take, totalCount, setPage }: Props) => {
  const totalPages = Math.ceil(totalCount / take);

  const handleChange = (_: ChangeEvent<unknown>, value: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return setPage(value - 1);
  };

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      {totalCount > take ? (
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handleChange}
            shape="rounded"
            color="primary"
            size={isSmallScreen ? "small" : "large"}
            showFirstButton
            showLastButton
            sx={{ p: 3 }}
          />
        </Stack>
      ) : null}
    </>
  );
};

export default Paging;
