import { Box, Typography } from "@mui/material";
import { useAppSelector } from "app";
import { selectApplication } from "features/configuration";
import { selectT9N } from "features/locale";
import React from "react";

type Props = {
  dense?: boolean;
};

const Disclaimer = ({ dense }: Props) => {
  const t9n = useAppSelector(selectT9N);
  const app = useAppSelector(selectApplication);

  return (
    <Box>
      <Typography variant="body2" pt={dense ? 0 : 5}>
        {t9n?.[`Disclaimer.${app}`] ?? t9n?.Disclaimer}
      </Typography>

      <Typography variant="body2" pt={1} mb={dense ? 0 : -3}>
        {t9n?.AxaltaAllRightsReserved}
      </Typography>
    </Box>
  );
};

export default Disclaimer;
