import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, FieldValues } from "react-hook-form";

import { FormProps, Options } from "./form.props";

type Props<T> = FormProps<T> & {
  label?: string;
  initialValues?: (string | number | undefined)[];
  options?: Options[];
  disableOnLastSelected?: boolean;
};

export const ControlledCheckBoxGroup = <T extends FieldValues>({
  name,
  label,
  control,
  setValue,
  trigger,
  initialValues,
  options,
  disableOnLastSelected,
  ...rest
}: Props<T>) => {
  const [selectedItems, setSelectedItems] = useState<any>(initialValues ?? []);

  const handleSelect = (value: any) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item: any) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    name && setValue && setValue(name, selectedItems, { shouldValidate: true });
  }, [setValue, name, selectedItems]);

  if (!name) return <></>;

  return (
    <>
      <FormControl {...rest}>
        {label && <FormLabel>{label}</FormLabel>}

        {options?.map(({ value, label }) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={({ fieldState: { error } }) => {
                    return (
                      <Checkbox
                        disabled={
                          disableOnLastSelected &&
                          selectedItems.includes(value) &&
                          selectedItems.length <= 1
                        }
                        checked={selectedItems.includes(value)}
                        onChange={async () => {
                          trigger && (await trigger(name));
                          handleSelect(value);
                        }}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={label}
              key={value}
            />
          );
        })}
      </FormControl>
    </>
  );
};

export default ControlledCheckBoxGroup;
