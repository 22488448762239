import { useTheme } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { colorService } from "services";
import { styled } from "@mui/system";

import { GraphData, GraphProps } from "./models";
import { PrintContext } from "shared/context";

const StyledTitleChart = styled(Chart)(({ color }) => ({
  "& .apexcharts-title-text": {
    "& tspan:nth-of-type(2)": {
      fill: color,
    },
  },
}));

type Props = GraphProps & {
  hideLegend?: boolean;
  horizontal?: boolean;
  legendColorIndex?: number;
  showTotalDataLabels?: boolean;
  total?: number;
  showDataLabels?: boolean;
};

const ColumnGraph = ({
  title,
  data,
  formatter,
  totalFormatter,
  colors,
  horizontal,
  legendColorIndex,
  showTotalDataLabels,
  total,
  showDataLabels,
  hideLegend
}: Props) => {
  const print = useContext(PrintContext);

  const t9n = useAppSelector(selectT9N);
  const theme = useTheme();

  const totalColor = data?.[0]?.total
    ? data?.[0].total > 0
      ? theme.palette.error.main
      : theme.palette.success.main
    : undefined;

  const xAxisTotals = data.reduce((acc: number[], item: GraphData) => {
    item.values.forEach((v, i) => {
      if (acc.length <= i) {
        acc.push(0);
      }
      acc[i] += v?.y ?? 0;
    });
    return acc;
  }, []);

  return (
    <StyledTitleChart
      color={totalColor}
      type="bar"
      height="100%"
      width={horizontal ? "85%" : "100%"}
      options={{
        chart: {
          stacked: true,
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              pan: false,
              download: !print,
            },
          },
          animations: {
            enabled: !print,
          },
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
            columnWidth: "50%",
            barHeight: horizontal ? "60%" : "100%",
          },
        },
        dataLabels: {
          enabled: showDataLabels,
          formatter: showDataLabels && formatter,
        },
        xaxis: {
          labels: {
            show: !horizontal,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: horizontal,
        },
        grid: {
          show: false,
        },
        noData: {
          text: t9n?.NoData,
        },
        title: {
          text: [
            title,
            (totalFormatter && totalFormatter(data[0]?.total)) ?? "",
          ] as any,
        },
        colors: [
          ({ dataPointIndex, seriesIndex }: any) => {
            const alpha = 1 - seriesIndex / 6;
            if (!colors || dataPointIndex >= colors.length) {
              return colorService.hex2rgba(
                window.Apex.colors[dataPointIndex % 2],
                alpha
              );
            }

            return colors[dataPointIndex]?.[seriesIndex];
          },
        ],
        legend: {
          show: !hideLegend,
          position: "top",
          markers: {
            fillColors: !colors
              ? data?.map((d, i) =>
                colorService.hex2rgba(theme.palette.primary.main, 1 - i / 6)
              )
              : colors[legendColorIndex ?? 1],
          },
          onItemClick: {
            toggleDataSeries: !print,
          },
          onItemHover: {
            highlightDataSeries: !print,
          },
        },
        tooltip: {
          enabled: !print,
          y: {
            formatter,
          },
        },
        states: {
          hover: {
            filter: {
              type: print ? "none" : "lighten",
            },
          },
          active: {
            filter: {
              type: print ? "none" : "darken",
            },
          },
        },
      }}
      series={data
        .filter((d) => d.values.some((v) => v.y))
        .map((d) => ({
          name: d.name,
          data: d.values.map((v, i) => ({
            x: showTotalDataLabels
              ? [v.x, formatter && formatter(total ?? xAxisTotals[i])]
              : v.x,
            y: v.y,
          })),
        }))}
    />
  );
};

export default ColumnGraph;
