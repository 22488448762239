import { createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicationType,
  Brand,
  EnergyComparison,
  EnergyComparisonFilter,
  EnergyProductType,
  EnergyStep,
  ListFilter,
} from "api/models";
import { energyComparisonService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";

export type EnergyComparisonState = {
  filters?: EnergyComparisonFilter;
  listFilters?: ListFilter;
  comparison?: EnergyComparison | null;
  steps?: EnergyStep[];
  upsertPending: boolean;
};

const initialState: EnergyComparisonState = {
  upsertPending: false
};

export const getEnergyComparisonById = createAsyncThunk(
  "get/energy/comparison/id",
  async (id: number, thunkAPI) => {
    return await energyComparisonService.getById(id);
  }
);

export const getEnergyFilters = createAsyncThunk(
  "get/energy/comparison/filters",
  async (arg, thunkAPI) => {
    return await energyComparisonService.getFilters();
  }
);

export const getEnergyListFilters = createAsyncThunk(
  "get/energy/compare/filter/list",
  async (_, thunkAPI) => {
    return await energyComparisonService.getListFilters();
  }
);

export const upsertEnergyComparison = createAsyncThunk(
  "upsert/energy/comparison",
  async (arg: EnergyComparison, { dispatch, rejectWithValue }) => {
    try {
      const id = await energyComparisonService.upsert(arg);
      if (id && id > 0) dispatch(getEnergyComparisonById(id));
      return id;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ComparisonSaveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const updateEnergyStep = createAsyncThunk(
  "update/energy/step",
  async (
    arg: { id: number; comparisonId: number; step: EnergyStep },
    { dispatch, rejectWithValue }
  ) => {
    const { id, comparisonId, step } = arg;
    try {
      await energyComparisonService.updateStep(id, step);

      if (id && id > 0) {
        dispatch(getEnergyComparisonById(comparisonId));
      }

      return step;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ComparisonSaveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

const energyComparisonSlice = createSlice({
  name: "energyComparison",
  initialState,
  reducers: {
    clearEnergyComparison: (state) => {
      state.comparison = undefined;
      state.steps = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEnergyFilters.fulfilled,
      (state, action: PayloadAction<EnergyComparisonFilter | null>) => {
        if (action.payload) {
          state.filters = action.payload;
        } 
      }
    );
    builder.addCase(
      getEnergyListFilters.fulfilled,
      (state, action: PayloadAction<ListFilter | null>) => {
        state.listFilters = action.payload ?? undefined;
      }
    );
    builder.addCase(
      getEnergyComparisonById.fulfilled,
      (state, action: PayloadAction<EnergyComparison | null>) => {
        if(!action.payload) return;
        const { steps, ...comparison } = action.payload;
        state.comparison = comparison
        state.steps = steps
      }
    );
    builder.addCase(
      upsertEnergyComparison.pending,
      (state) => {
        state.upsertPending = true;
      }
    );
    builder.addCase(
      upsertEnergyComparison.fulfilled,
      (state, action: PayloadAction<number | null>) => {
        state.upsertPending = false;
        
        if (state.comparison && action.payload && action.payload > 0)
          state.comparison.id = action.payload;
      }
    );
    builder.addCase(
      upsertEnergyComparison.rejected,
      (state) => {
        state.upsertPending = false;
      }
    );
  },
});

const selectSelf = (state: RootState) => state.energyComparison;
export const selectEnergyFilters = createDraftSafeSelector(
  selectSelf,
  (state) => {
    if (!state.filters || !state.comparison) 
      return state.filters;

    const axaltaBrand = state.filters?.brands.find(
      (b) => b.id === state.comparison?.brandId
    ) || {name: state.comparison?.brand} as Brand;

    return {
      ...state.filters,
      competitorBrands: [
        axaltaBrand,
        ...(state.filters?.competitorBrands.filter(b => b.active) ?? []),
      ],
    };
  }
);
export const selectEnergyConsumptionFactor = (
  productType?: EnergyProductType
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    switch (productType) {
      case EnergyProductType.Clearcoat:
        return state.filters?.ccFactor;
      case EnergyProductType.Basecoat:
        return state.filters?.bcFactor;
      default:
        return state.filters?.ucFactor;
    }
  });
export const selectEnergyListFilters = createDraftSafeSelector(
  selectSelf,
  (state) => state.listFilters
);
export const selectEnergyComparison = createDraftSafeSelector(
  selectSelf,
  (state) => state.comparison
);
export const selectEnergySteps = createDraftSafeSelector(
  selectSelf,
  (state) => state.steps
);
export const selectEnergyStepByProductType = (productType: EnergyProductType) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.steps?.find((s) => s.productType === productType)
  );
export const selectEnergyStepIds = createDraftSafeSelector(
  selectSelf,
  (state) => state.steps?.map((s) => s.id)
);
export const selectEnergyStepById = (id?: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    id ? state.steps?.find((s) => s.id === id) : undefined
  );
export const selectEnergyComparisonAxaltaBrand = createDraftSafeSelector(
  selectSelf,
  (state) => ({
    id: state.comparison?.brandId,
    name: state.comparison?.brand,
  })
);
export const selectExtraScannerModuleInformationAvailable =
  createDraftSafeSelector(
    (state: RootState) => state,
    (state) =>
      !!state.energyComparison.comparison &&
      !state.energyComparison.comparison?.modules?.every((m) =>
        state.user.profile?.applications
          .find((a) => a.app === ApplicationType.QccSales)
          ?.extraModules?.filter((em) => em.active)
          ?.map((em) => em.app)
          ?.includes(m)
      )
  );
export const selectUpsertEnergyComparisonPending =
  createDraftSafeSelector(selectSelf, (state) => state.upsertPending);


// function mapEnergyStep(step: EnergyStep) {
//   if(!step?.detail || !step?.competitorDetail) return step;

//   return {
//     ...step,
//     detail: mapEnergyStepDetail(step.detail),
//     competitorDetail: mapEnergyStepDetail(step.competitorDetail),
//   }
// };


// function mapEnergyStepDetail (detail: EnergyStepDetail): EnergyStepDetail { 
//   const totalCostPerYear = (detail.totalEnergyCostPerYear ?? 0) + (detail.productCostPerYear ?? 0);
//   return {
//     ...detail,
//     totalEnergyCostPer5Years: (detail.totalEnergyCostPerYear || 0) * 5,
//     totalEnergyCostPer10Years: (detail.totalEnergyCostPerYear || 0) * 10,
//     productCostPer5Years: (detail.productCostPerYear || 0) * 5,
//     productCostPer10Years: (detail.productCostPerYear || 0) * 5,
//     totalCost: (detail.totalEnergyCost ?? 0) + (detail.productCost ?? 0),
//     totalcost
//     totalCostPer5Years: (detail.totalEnergyCostPerYear || 0) * 5,
    
//   }
// }

// function updateStateEnergyStep(
//   state: Draft<EnergyComparisonState>,
//   step: EnergyStep | null
// ) {
//   let stateStep = state.steps?.find((c) => c.id === step?.id);

//   if (stateStep) {
//     stateStep.product = step?.product;
//     stateStep.productCode = step?.productCode;
//     stateStep.activatorThinner = step?.activatorThinner;
//     stateStep.applicationTimeInSec = step?.applicationTimeInSec;
//     stateStep.flashTimeInSec = step?.flashTimeInSec;
//     stateStep.dryingMode = step?.dryingMode;
//     stateStep.dryingTimeInSec = step?.dryingTimeInSec;
//     stateStep.dryingTemperature = step?.dryingTemperature;
//     stateStep.rampupTimeInSec = step?.rampupTimeInSec;
//     stateStep.cooldownTimeInSec = step?.cooldownTimeInSec;
//     stateStep.price = step?.price;
//     stateStep.quantity = step?.quantity;
//     stateStep.quantityGrams = step?.quantityGrams;
//     stateStep.discount = step?.discount;
//     stateStep.vocPerLiter = step?.vocPerLiter;

//     stateStep.competitorBrand = step?.competitorBrand;
//     stateStep.competitorProduct = step?.competitorProduct;
//     stateStep.competitorProductCode = step?.competitorProductCode;
//     stateStep.competitorActivatorThinner = step?.competitorActivatorThinner;
//     stateStep.competitorApplicationTimeInSec = step?.competitorApplicationTimeInSec;
//     stateStep.competitorFlashTimeInSec = step?.competitorFlashTimeInSec;
//     stateStep.competitorDryingMode = step?.competitorDryingMode;
//     stateStep.competitorDryingTimeInSec = step?.competitorDryingTimeInSec;
//     stateStep.competitorDryingTemperature = step?.competitorDryingTemperature;
//     stateStep.competitorRampupTimeInSec = step?.competitorRampupTimeInSec;
//     stateStep.competitorCooldownTimeInSec = step?.competitorCooldownTimeInSec;
//     stateStep.competitorPrice = step?.competitorPrice;
//     stateStep.competitorQuantity = step?.competitorQuantity;
//     stateStep.competitorQuantityGrams = step?.competitorQuantityGrams;
//     stateStep.competitorDiscount = step?.competitorDiscount;
//     stateStep.competitorVocPerLiter = step?.competitorVocPerLiter;
//   }
// }

export const { clearEnergyComparison } = energyComparisonSlice.actions;
export default energyComparisonSlice.reducer;
