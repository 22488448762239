import { useEffect, useRef, useState } from "react";

const useImage = (fileName: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../../assets/img/${fileName}`);

        // Fix memory leak
        // When component is unmounted after async call finishes, do not update state
        if (!mountedRef.current) return null;

        setImage(response.default);
      } catch (err: any) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [fileName]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    loading,
    error,
    image,
  };
};

export default useImage;
