import { createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrencyError } from "api/errors";
import { CompareStep, StepOverview, StepOverviewFilter, StepToCompare } from "api/models";
import { comparisonService } from "api/services";
import { RootState } from "app";

export type CompareState = {
  overview?: StepOverview;
  comparison?: CompareStep;
  comparisonError?: string | null;
  currentFilters?: Record<string, string>;
  result?: any;
};

const initialState: CompareState = {};

export const getCompareList = createAsyncThunk(
  "get/compare/list",
  async (arg: StepOverviewFilter, thunkAPI) => {
    return await comparisonService.getSteps(arg);
  }
);

export const getCompareStep = createAsyncThunk<
  CompareStep | null,
  StepOverviewFilter,
  {
    rejectValue: string | null;
  }
>("get/compare", async (arg: StepOverviewFilter, { rejectWithValue }) => {
  try {
    return await comparisonService.getCompareSteps(arg);
  } catch (error: any) {
    if (error instanceof CurrencyError) return rejectWithValue("CurrencyError");
    return rejectWithValue(null);
  }
});

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<StepToCompare>) => {
      if (action.payload) {
        state.currentFilters = Object.assign(action.payload);
      }
    },
    clearCompare: (state) => {
      state.overview = undefined;
      state.currentFilters = undefined;
      state.result = undefined;
      state.comparison = undefined;
      state.comparisonError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCompareList.fulfilled,
      (state, action: PayloadAction<StepOverview | null>) => {
        state.overview = action.payload ?? undefined;
      }
    );
    builder.addCase(
      getCompareStep.fulfilled,
      (state, action: PayloadAction<CompareStep | null | undefined>) => {
        state.comparisonError = undefined;
        state.comparison = action.payload ?? undefined;
      }
    );
    builder.addCase(
      getCompareStep.rejected,
      (state, action: PayloadAction<string | null | undefined>) => {
        state.comparisonError = action.payload;
      }
    );
  },
});

const selectSelf = (state: RootState) => state.compare;
export const selectCompareListIds = createDraftSafeSelector(
  selectSelf,
  (state) => state.overview?.steps.map((s) => s.id)
);
export const selectCompareListTotalCount = createDraftSafeSelector(
  selectSelf,
  (state) => state.overview?.totalCount
);
export const selectCompareStepById = (id: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.overview?.steps?.find((c) => c.id === id)
  );

export const selectCompareCurrentFilters = createDraftSafeSelector(
  selectSelf,
  (state) => state.currentFilters
);
export const selectCompareStep = createDraftSafeSelector(
  selectSelf,
  (state) => state.comparison
);
export const selectCompareError = createDraftSafeSelector(
  selectSelf,
  (state) => state.comparisonError
);

export const { clearCompare, setFilters } = compareSlice.actions;
export default compareSlice.reducer;
