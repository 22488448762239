import { authService } from "api/services";
import { useAppDispatch, useAppSelector } from "app";
import { selectApplication, setApplication } from "features/configuration";
import { getUserProfile, selectUser } from "features/user";
import React, { useEffect } from "react";

const AuthSuspense = ({ children }: React.PropsWithChildren<{}>) => {
  const user = useAppSelector(selectUser);
  const app = useAppSelector(selectApplication);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user && !app) {
      const defaultApp = user.applications.find((a) => a.active);
      if (defaultApp) dispatch(setApplication(defaultApp.app));
    }
  }, [dispatch, user, app]);

  if (user === undefined) return <></>;
  if (user === null && !window.location.pathname.includes("/unauthorized")) {
    authService.login(window.location.pathname);
    return <></>;
  }

  return <>{children}</>;
};

export default AuthSuspense;
