import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ArchiveFilter,
  EnergyComparisonOverview,
  EnergyComparisonOverviewFilter,
} from "api/models";
import { energyComparisonService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";

export type EnergyArchiveState = {
  overview: EnergyComparisonOverview;
  currentFilters: Record<string, string>;
};

const initialState: EnergyArchiveState = {
  overview: {
    totalCount: 0,
    comparisons: [],
  },
  currentFilters: {},
};

export const getEnergyArchive = createAsyncThunk(
  "get/energy/archive/comparison/list",
  async (arg: EnergyComparisonOverviewFilter, thunkAPI) => {
    return await energyComparisonService.get(arg);
  }
);

export const deleteEnergyComparison = createAsyncThunk(
  "delete/energy/archive/comparison",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await energyComparisonService.remove(id);
      return id;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ComparisonDeleteError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

const energyArchiveSlice = createSlice({
  name: "energyArchive",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<ArchiveFilter>) => {
      if (action.payload) {
        state.currentFilters = Object.assign(action.payload);
      }
    },
    clearArchive: (state) => {
      state.overview = initialState.overview;
      state.currentFilters = initialState.currentFilters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEnergyArchive.fulfilled,
      (state, action: PayloadAction<EnergyComparisonOverview | null>) => {
        state.overview = action.payload ?? initialState.overview;
      }
    );
    builder.addCase(
      deleteEnergyComparison.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.overview = {
          ...state.overview,
          comparisons: state.overview.comparisons.filter(
            (c) => c.id !== action.payload
          ),
        }
      }
    );
  },
});

const selectSelf = (state: RootState) => state.energyArchive;
export const selectEnergyArchiveIds = createDraftSafeSelector(
  selectSelf,
  (state) => {
    return state.overview.comparisons?.map((c) => c.id);
  }
);

export const selectEnergyArchiveItemById = (id: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.overview?.comparisons?.find((c) => c.id === id)
  );
export const selectEnergyArchiveTotalCount = createDraftSafeSelector(
  selectSelf,
  (state) => state.overview?.totalCount
);
export const selectEnergyArchiveCurrentFilters = createDraftSafeSelector(
  selectSelf,
  (state) => state.currentFilters
);

export const { setFilters, clearArchive } = energyArchiveSlice.actions;
export default energyArchiveSlice.reducer;
