import { useTheme } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { PrintContext } from "shared/context";

import { GraphProps } from "./models";

const DonutGraph = ({
  title,
  data,
  formatter,
  totalFormatter,
  colors,
}: GraphProps) => {
  const print = useContext(PrintContext);

  const t9n = useAppSelector(selectT9N);
  const theme = useTheme();

  const graphData = data?.[0];
  const totalPositive = graphData?.total
    ? graphData.total > 0
      ? true
      : false
    : undefined;

  return (
    <Chart
      type="donut"
      options={{
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: !print,
            },
          },
          animations: {
            enabled: !print,
          },
        },
        noData: {
          text: t9n?.NoData,
        },
        title: {
          text: title,
        },
        labels: graphData.values.map((v) => v.x),
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            startAngle: -180,
            endAngle: 180,
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                value: {
                  color:
                    totalPositive === true
                      ? theme.palette.error.main
                      : totalPositive === false
                      ? theme.palette.success.main
                      : undefined,
                  offsetY: -10,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: undefined,
                  formatter: (_: any) =>
                    totalFormatter && totalFormatter(graphData?.total),
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.2,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: !print,
          y: {
            formatter,
          },
        },
        colors: colors ?? window.Apex.colors,
        states: {
          hover: {
            filter: {
              type: print ? "none" : "lighten",
            },
          },
          active: {
            filter: {
              type: print ? "none" : "darken",
            },
          },
        },
      }}
      series={graphData.values.map((v) => v.y)}
    />
  );
};

export default DonutGraph;
