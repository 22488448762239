import { Box } from "@mui/material";
import React from "react";

type Props = {
  index: number;
  value: number;
};

const TabPanel = ({
  index,
  value,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ px: 0, py: 2 }}>{children}</Box>}
    </Box>
  );
};

export default TabPanel;
