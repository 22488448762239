import { UserProfile, SetUserProfile } from "api/models";
import utils from "api/utils";

const baseUrl = "api/user";

const getProfile = async () => {
  return utils.apiGet<UserProfile>(baseUrl);
};

const setProfile = async (userProfile: SetUserProfile) => {
  return utils.apiCommand(baseUrl, userProfile);
};

const userService = { getProfile, setProfile };
export default userService;
