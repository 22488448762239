import { createAsyncThunk } from "@reduxjs/toolkit";
import { SetUserRole, Country } from "api/models";
import { configurationService } from "api/services";
import { setMessage } from "features/message";

export const getUsers = createAsyncThunk(
  "get/configuration/users",
  async (arg, thunkAPI) => {
    return await configurationService.getUsers();
  }
);

export const setUserRole = createAsyncThunk(
  "put/configuration/userrole",
  async (arg: SetUserRole, thunkAPI) => {
    await configurationService.setUserRole(arg);
    return arg;
  }
);

export const deleteUser = createAsyncThunk(
  "delete/configuration/user",
  async (userId: number, { dispatch, rejectWithValue }) => {
    try {
      await configurationService.deleteUser(userId);
      return userId;
    } catch (error) {
      dispatch(
        setMessage({
          message: "UserDeleteError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const getCountries = createAsyncThunk(
  "get/configuration/countries",
  async (arg, thunkAPI) => {
    return await configurationService.getCountries();
  }
);

export const getCountryFilters = createAsyncThunk(
  "get/configuration/country/filters",
  async (arg, thunkAPI) => {
    return await configurationService.getCountryFilters();
  }
);

export const upsertCountry = createAsyncThunk(
  "upsert/configuration/country",
  async (
    args: { countryCode: string | undefined; country: Country },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await configurationService.upsertCountry(
        args.countryCode ?? "",
        args.country
      );

      dispatch(
        setMessage({
          message: args.countryCode
            ? "CountryUpdatedSuccess"
            : "CountryAddedSuccess",
          status: "success",
          timeOut: 5000,
        })
      );

      return args.country;
    } catch (error) {
      dispatch(
        setMessage({
          message: "CountrySaveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "delete/configuration/country",
  async (countryCode: string, { dispatch, rejectWithValue }) => {
    try {
      await configurationService.deleteCountry(countryCode);
      return countryCode;
    } catch (error) {
      dispatch(
        setMessage({
          message: "CountryDeleteError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const getBrands = createAsyncThunk(
  "get/configuration/brands",
  async (arg, thunkAPI) => {
    return await configurationService.getBrands();
  }
);

export const addBrand = createAsyncThunk(
  "insert/configuration/brand",
  async (args: string, { dispatch, rejectWithValue }) => {
    try {
      await configurationService.addBrand(args);

      dispatch(
        setMessage({
          message: "BrandAddedSuccess",
          status: "success",
          timeOut: 5000,
        })
      );

      dispatch(getBrands()); // Get custom BrandId
    } catch (error) {
      dispatch(
        setMessage({
          message: "BrandSaveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const deleteBrand = createAsyncThunk(
  "delete/configuration/brand",
  async (brandId: number, { dispatch, rejectWithValue }) => {
    try {
      await configurationService.deleteBrand(brandId);
      return brandId;
    } catch (error) {
      dispatch(
        setMessage({
          message: "BrandDeleteError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const getProducts = createAsyncThunk(
  "get/configuration/products",
  async (arg: { brandId: number | undefined; language: string }, thunkAPI) => {
    if (!arg.brandId) return undefined;
    return await configurationService.getProducts(arg.brandId, arg.language);
  }
);

export const getProductDetails = createAsyncThunk(
  "get/configuration/product/details",
  async (arg: { brandId: number | undefined; language: string }, thunkAPI) => {
    if (!arg.brandId) return undefined;
    return await configurationService.getProductDetails(
      arg.brandId,
      arg.language
    );
  }
);
