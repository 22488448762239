import { Grid, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { Image } from "shared/components";
import { PrintContext } from "shared/context";

import { GraphProps } from "./models";

const ComparisonGraph = ({ data, totalFormatter }: GraphProps) => {
  const print = useContext(PrintContext);
  const theme = useTheme();

  const gradientColorStops = (total?: number, reverseColors?: boolean) => {
    let color: (string | undefined)[] = [
      theme.palette.secondary.graph?.light,
      theme.palette.secondary.graph?.main,
      theme.palette.secondary.graph?.dark,
      theme.palette.secondary.graph?.dark,
      theme.palette.secondary.graph?.main,
      theme.palette.secondary.graph?.light,
    ];

    if (total) {
      color = [
        theme.palette.success.graph?.light,
        theme.palette.success.graph?.main,
        theme.palette.success.graph?.dark,
        theme.palette.error.graph?.light,
        theme.palette.error.graph?.main,
        theme.palette.error.graph?.dark,
      ];

      if ((reverseColors && total > 0) 
      || (!reverseColors && total < 0)) 
        color.reverse();
    }

    return [
      [
        {
          offset: 0,
          color: color[0],
        },
        {
          offset: total && total < 0 ? 65 : 35,
          color: color[1],
        },
        {
          offset: 100,
          color: color[2],
        },
      ],
      [
        {
          offset: 0,
          color: color[3],
        },
        {
          offset: total && total >= 0 ? 35 : 65,
          color: color[4],
        },
        {
          offset: 100,
          color: color[5],
        },
      ],
    ];
  };

  return (
    <>
      <Grid container columns={24}>
        {data.filter(d => !d.hide).map((d, i) => {
          return (
            <React.Fragment key={i}>
              <Grid
                item
                xs={d.icon ? 5 : 6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle1" fontSize="0.8em">
                  {d.name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={
                    d.total && 
                    ((d.reverseColors && d.total > 0) 
                    || (!d.reverseColors && d.total < 0))
                      ? theme.palette.success.main
                      : theme.palette.error.main
                  }
                >
                  {!d.hideTotal && totalFormatter && totalFormatter(d.total)}
                </Typography>
              </Grid>

              {d.values.map((v, j) => (
                <React.Fragment key={j}>
                  <Grid item xs={d.icon ? 8 : 9}>
                    <Chart
                      type="bar"
                      height="50%"
                      width="100%"
                      options={{
                        data: d.values,
                        chart: {
                          stacked: true,
                          sparkline: {
                            enabled: true,
                          },
                          animations: {
                            enabled: !print,
                          },
                        },
                        plotOptions: {
                          bar: {
                            borderRadius: [25],
                            horizontal: true,
                          },
                        },
                        dataLabels: {
                          enabled:
                            (v.y ?? 0) /
                              Math.max(...d.values.map((v) => v.y ?? 0)) >=
                            0.35,
                          formatter: d.formatter,
                        },
                        xaxis: {
                          tickAmount: 1,
                        },
                        yaxis: {
                          show: false,
                          min: (min: any) =>
                            j % 2 === 0
                              ? -Math.max(...d.values.map((v) => v.y ?? 0)) * 1.1
                              : 0,
                          max: (max: any) =>
                            j % 2 !== 0
                              ? Math.max(...d.values.map((v) => v.y ?? 0)) * 1.1
                              : 0,
                        },
                        tooltip: {
                          enabled: !print,
                          x: {
                            show: true,
                          },
                          y: {
                            formatter: d.formatter,
                          },
                        },
                        labels: [
                          d.name,
                          (totalFormatter && totalFormatter(d.total)) ?? "",
                        ],
                        fill: {
                          type: "gradient",
                          gradient: {
                            shade: "light",
                            colorStops: gradientColorStops(d.total, d.reverseColors)[j % 2],
                          },
                        },
                        states: {
                          hover: {
                            filter: {
                              type: print ? "none" : "lighten",
                            },
                          },
                          active: {
                            filter: {
                              type: print ? "none" : "darken",
                            },
                          },
                        },
                      }}
                      series={[
                        {
                          name: v?.x,
                          data: [j % 2 === 0 ? v.y && -v.y : v.y],
                        },
                      ]}
                    />
                  </Grid>

                  {j % 2 === 0 && d.icon && (
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRight: print ? "none" : "1px solid",
                        borderLeft: print ? "none" : "1px solid",
                        borderColor: "divider",
                      }}
                      className="print-pl-1"
                    >
                      <Image fileName={d.icon} width="65" height="65" />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export default ComparisonGraph;
