import { Container, Typography } from "@mui/material";
import React, { Component, ErrorInfo, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

/*
    A JavaScript error in a part of the UI shouldn’t break the whole app. 
    To solve this problem for React users, React 16 introduces a new concept of an “error boundary”.
    https://reactjs.org/docs/error-boundaries.html
*/
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="xl" sx={{ mt: 2 }}>
          <Typography variant="h1">Something went wrong!</Typography>
          <Typography variant="body1">
            Our apologies, an error occurred while processing your request.
            Please contact support.
          </Typography>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
