import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ApplicationType,
  SetUserProfile,
  UserBrand,
  UserProfile,
} from "api/models";
import { userService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";

export type UserState = {
  profile?: UserProfile | null;
  language?: string;
};

const initialState: UserState = {};

export const getUserProfile = createAsyncThunk(
  "get/userProfile",
  async (arg, thunkAPI) => {
    return await userService.getProfile();
  }
);

export const setUserProfile = createAsyncThunk(
  "set/userProfile",
  async (arg: SetUserProfile, { dispatch, rejectWithValue }) => {
    try {
      await userService.setProfile(arg);

      dispatch(
        setMessage({
          message: "ProfileUpdateSuccess",
          status: "success",
          timeOut: 5000,
        })
      );

      return arg;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ProfileUpdateError",
          status: "error",
        })
      );

      return rejectWithValue(null);
    }
  }
);

const userSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state) => {
      state.profile = undefined;
    });
    builder.addCase(
      getUserProfile.fulfilled,
      (state, action: PayloadAction<UserProfile | null>) => {
        state.profile = action.payload;
      }
    );
    builder.addCase(getUserProfile.rejected, (state) => {
      state.profile = null;
    });
    builder.addCase(
      setUserProfile.fulfilled,
      (state, action: PayloadAction<SetUserProfile | null>) => {
        if (!action.payload) return;

        const { extraModules, ...payload } = action.payload;

        state.profile = {
          ...state.profile!,
          ...payload,
          brands: state.profile!.brands.map((b) => ({
            ...b,
            active: action.payload?.brands.includes(b.id) ?? false,
          })),
          applications: state.profile!.applications.map((a) => ({
            ...a,
            active: action.payload?.defaultTool === a.app,
            extraModules: a.extraModules?.map((em) => ({
              ...em,
              active: extraModules?.includes(em.app) ?? false,
            })),
          })),
        };
      }
    );
  },
});

const selectSelf = (state: RootState) => state.user;
export const selectLanguage = createDraftSafeSelector(
  selectSelf,
  (state) => state.language
);
export const selectUser = createDraftSafeSelector(
  selectSelf,
  (state) => state.profile
);
export const selectRole = createDraftSafeSelector(
  selectSelf,
  (state) => state.profile?.role
);
export const selectApps = createDraftSafeSelector(
  selectSelf,
  (state) => state.profile?.applications
);
export const selectUserBrands = (application?: ApplicationType) =>
  createDraftSafeSelector(selectSelf, (state) => {
    let filteredBrands: UserBrand[] | undefined = [];

    switch (application) {
      case ApplicationType.Qcc:
        filteredBrands = state.profile?.brands?.filter((b) => b.useQcc);
        break;
      case ApplicationType.QccSales:
        filteredBrands = state.profile?.brands?.filter((b) => b.useQccSales);
        break;
      default:
        return state.profile?.brands;
    }

    return filteredBrands?.some((b) => b)
      ? filteredBrands
      : state.profile?.brands;
  });

export const selectActiveModules = (application?: ApplicationType) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.profile?.applications
      .find((a) => a.app === application)
      ?.extraModules?.filter((em) => em.active)
      ?.map((em) => em.app)
  );
export const selectModuleEnabled = (module?: ApplicationType) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.profile?.applications.some(
      (a) => a.extraModules?.find((em) => em.app === module)?.active
    )
  );
export const selectModules = (application?: ApplicationType) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.profile?.applications
      .find((a) => a.app === application)
      ?.extraModules
      ?.map((em) => em.app)
  );
export const selectMyProfileForm = createDraftSafeSelector(
  selectSelf,
  (state) => ({
    name: state.profile!.name,
    email: state.profile!.email,
    defaultTool: state.profile!.applications.find((a) => a.active)!.app,
    extraModules: state.profile!.applications.flatMap((a) =>
      a.extraModules?.filter((em) => em.active).map((em) => em.app)
    ),
    brands: state.profile!.brands.filter((b) => b.active).map((b) => b.id),
  })
);

export const { setLanguage } = userSlice.actions;
export default userSlice.reducer;
