import utils from "api/utils";

const baseUrl = "api/i18n";

const getTranslations = async (languageCode: string) => {
  return utils.apiGet<Record<string, string>>(baseUrl, {
    languageCode,
  });
};

const getLanguages = async () => {
  return await utils.apiGet<Record<string, string>>(`${baseUrl}/languages`);
};

const i18nService = { getTranslations, getLanguages };
export default i18nService;
