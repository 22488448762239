import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { dateService } from "services";
import Disclaimer from "./Disclaimer";

type Props = {
  lastPage?: boolean;
};

const Page = ({ lastPage, children }: React.PropsWithChildren<Props>) => (
  <Box
    sx={{
      pageBreakInside: "avoid",
      pageBreakAfter: lastPage ? "never" : "always",
    }}
  >
    {children}

    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        display: "none",
        displayPrint: "block",
        width: "90%",
        margin: "auto"
      }}
    >
      <Divider color="secondary" />

      <Disclaimer dense />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography color="secondary">
          {dateService.toDateString(new Date())}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default Page;
