import { useTheme } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { colorService } from "services";
import { PrintContext } from "shared/context";

import { GraphProps } from "./models";

type Props = GraphProps & {
  hideLegend?: boolean;
  hideYaxis?: boolean;
};

const StackedGraph = ({
  title,
  data,
  formatter,
  colors,
  hideLegend,
  hideYaxis,
}: Props) => {
  const print = useContext(PrintContext);
  const t9n = useAppSelector(selectT9N);

  const theme = useTheme();

  if (!data) return <></>;

  return (
    <Chart
      type="bar"
      height={hideLegend === true ? 565 : 600}
      options={{
        chart: {
          stacked: true,
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              pan: false,
              download: !print,
            },
          },
          animations: {
            enabled: !print,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter,
        },
        xaxis: {
          axisBorder: {
            show: !print,
          },
          axisTicks: {
            show: !print,
          },
        },
        yaxis: {
          show: !hideYaxis,
          labels: {
            formatter,
          },
        },
        grid: {
          show: !hideYaxis,
        },
        noData: {
          text: t9n?.NoData,
        },
        title: {
          text: title,
        },
        colors: [
          ({ dataPointIndex, seriesIndex }: any) => {
            const alpha = 1 - seriesIndex / 6;
            if (!colors)
              return colorService.hex2rgba(
                window.Apex.colors[dataPointIndex % 2],
                alpha
              );

            return colors[dataPointIndex % 2]?.[seriesIndex];
          },
        ],
        legend: {
          show: !hideLegend,
          markers: {
            fillColors: !colors
              ? data?.map((d, i) =>
                  colorService.hex2rgba(theme.palette.primary.main, 1 - i / 6)
                )
              : colors[1],
          },
          onItemClick: {
            toggleDataSeries: !print,
          },
          onItemHover: {
            highlightDataSeries: !print,
          },
        },
        tooltip: {
          enabled: !print,
        },
        states: {
          hover: {
            filter: {
              type: print ? "none" : "lighten",
            },
          },
          active: {
            filter: {
              type: print ? "none" : "darken",
            },
          },
        },
      }}
      series={data.map((d) => ({
        name: d.name,
        data: d.values.map((v) => ({ x: v.x, y: v.y })),
      }))}
    />
  );
};

export default StackedGraph;
