import { useCallback, useState } from "react";

const useToggle = (
  onClickedCallback?: Function
): [HTMLElement | null, boolean, any, any] => {
  const [menuAnchorEl, setmenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setmenuAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMenuClose = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setmenuAnchorEl(null);
      if (onClickedCallback) onClickedCallback();
    },
    [onClickedCallback]
  );

  return [menuAnchorEl, menuOpen, handleMenuClick, handleMenuClose];
};

export default useToggle;
