import { createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ComparisonOverview,
  ComparisonOverviewFilter,
  ArchiveFilter,
} from "api/models";
import { comparisonService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";

export type ArchiveState = {
  showArchived: boolean;
  overview?: ComparisonOverview;
  currentFilters?: Record<string, string>;
};

const initialState: ArchiveState = {
  showArchived: true,
};

export const getArchive = createAsyncThunk(
  "get/archive/comparison/list",
  async (arg: ComparisonOverviewFilter, thunkAPI) => {
    return await comparisonService.get(arg);
  }
);

export const deleteComparison = createAsyncThunk(
  "delete/comparison",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await comparisonService.remove(id);
      return id;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ComparisonDeleteError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

export const archiveComparison = createAsyncThunk(
  "archive/comparison",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await comparisonService.archive(id);
      return id;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ArchiveError",
          status: "error",
        })
      );
      return rejectWithValue(null);
    }
  }
);

const archiveSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {
    switchShowTemporary: (state) => {
      state.showArchived = !state.showArchived;
    },
    setFilters: (state, action: PayloadAction<ArchiveFilter>) => {
      if (action.payload) {
        state.currentFilters = Object.assign(action.payload);
      }
    },
    clearArchive: (state) => {
      state.overview = undefined;
      state.currentFilters = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getArchive.fulfilled,
      (state, action: PayloadAction<ComparisonOverview | null>) => {
        state.overview = action.payload ?? undefined;
      }
    );
    builder.addCase(
      deleteComparison.fulfilled,
      (state, action: PayloadAction<number>) => {
        const index = state.overview?.comparisons?.findIndex(
          (c) => c.id === action.payload
        );
        if (index !== undefined && index > -1) {
          if (!state.overview?.comparisons[index].dateArchived)
            state.overview!.totalOpenCount! -= 1;
          state.overview?.comparisons?.splice(index, 1);
          state.overview!.totalCount! -= 1;
        }
      }
    );
    builder.addCase(
      archiveComparison.fulfilled,
      (state, action: PayloadAction<number>) => {
        const index = state.overview?.comparisons?.findIndex(
          (c) => c.id === action.payload
        );
        if (index !== undefined && index > -1) {
          state.overview?.comparisons?.splice(index, 1);
          state.overview!.totalOpenCount! -= 1;
          state.overview!.totalCount! -= 1;
        }
      }
    );
  },
});

const selectSelf = (state: RootState) => state.archive;
export const selectShowArchived = createDraftSafeSelector(
  selectSelf,
  (state) => state.showArchived
);
export const selectArchiveIds = createDraftSafeSelector(selectSelf, (state) => {
  if (!state.overview) return [];
  return state.overview.comparisons?.map((c) => c.id);
});

export const selectArchiveItemById = (id: number) =>
  createDraftSafeSelector(selectSelf, (state) =>
    state.overview?.comparisons?.find((c) => c.id === id)
  );
export const selectArchiveTotalCount = createDraftSafeSelector(
  selectSelf,
  (state) => state.overview?.totalCount
);
export const selectArchiveTotalOpenCount = createDraftSafeSelector(
  selectSelf,
  (state) => state.overview?.totalOpenCount
);
export const selectArchiveCurrentFilters = createDraftSafeSelector(
  selectSelf,
  (state) => state.currentFilters
);

export const { switchShowTemporary, setFilters, clearArchive } = archiveSlice.actions;
export default archiveSlice.reducer;
