import { ActiveRoutePath, RoutePathDefinition } from "app/routing";
import { matchPath, PathMatch } from "react-router-dom";

export const joinPaths = (paths: string[]): string =>
  paths.join("/").replace(/\/\/+/g, "/");

export const mapDefinitionToActivePath = (
  definitions: RoutePathDefinition[],
  locationPathname: string,
  parentPath: string = ""
): ActiveRoutePath[] => {
  const activeRoutePaths: ActiveRoutePath[] = [];

  definitions.forEach((definition) => {
    const pathPatternWithParent = joinPaths([parentPath, definition.path]);
    const match = matchPath(
      { path: pathPatternWithParent, end: false },
      locationPathname
    );

    if (!match) {
      return;
    }

    if (isResolvedAsActive(match.pathname, locationPathname, definition)) {
      const activeRoutePath: ActiveRoutePath = {
        definition: definition,
        title:
          typeof definition.title === "function"
            ? definition.title({
                definition,
                match,
                locationPathname: locationPathname,
              })
            : definition.title,
        alias: definition.alias,
        match: match,
      };
      addActiveRoutePathIfPossible(activeRoutePaths, activeRoutePath);

      if (definition.children) {
        const nestedMatches = mapDefinitionToActivePath(
          definition.children,
          locationPathname,
          pathPatternWithParent
        );
        nestedMatches.forEach((activePath) => {
          addActiveRoutePathIfPossible(activeRoutePaths, activePath);
        });
      }
    }
  });
  return activeRoutePaths;
};

function addActiveRoutePathIfPossible(
  activeRoutePaths: ActiveRoutePath[],
  activePath: ActiveRoutePath
) {
  if (canBeAddedToActiveRoutes(activeRoutePaths, activePath.match)) {
    activeRoutePaths.push(activePath);
  }
}

function isResolvedAsActive(
  toPathname: string,
  locationPathname: string,
  definition: RoutePathDefinition
) {
  return (
    isPathActiveForLocation(toPathname, locationPathname) &&
    isNotCatchAll(definition.path || "")
  );
}

function isPathActiveForLocation(pathName: string, locationPathname: string) {
  return (
    locationPathname === pathName || locationPathname.startsWith(pathName)
  );
}

function canBeAddedToActiveRoutes(
  activeRoutePaths: ActiveRoutePath[],
  match: PathMatch<string>
) {
  return (
    isNotSameAsPreviousMatch(activeRoutePaths, match) &&
    isMoreSpecificThanPreviousMatch(activeRoutePaths, match.pathname)
  );
}

function getPreviousMatch(
  previousMatches: ActiveRoutePath[]
): ActiveRoutePath | undefined {
  return previousMatches[previousMatches.length - 1];
}

function isNotSameAsPreviousMatch(
  previousMatches: ActiveRoutePath[],
  match: PathMatch<string>
): boolean {
  const previousMatchedPathname =
    getPreviousMatch(previousMatches)?.match.pattern ?? "";
  return previousMatchedPathname !== match.pattern;
}

function isMoreSpecificThanPreviousMatch(
  previousMatches: ActiveRoutePath[],
  toPathname: string
): boolean {
  const previousMatchedPathname =
    getPreviousMatch(previousMatches)?.match.pathname ?? "";
  return toPathname.length > previousMatchedPathname.length;
}

function isNotCatchAll(path: string) {
  return path !== "*";
}
