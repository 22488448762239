import {
  Forecast,
  ForecastCriteria,
  CompareStep,
  Comparison,
  ComparisonFilter,
  ComparisonOverview,
  ComparisonOverviewFilter,
  Step,
  StepOverview,
  StepOverviewFilter,
  ListFilter,
} from "api/models";
import utils from "api/utils";

const baseUrl = "api/comparison";

const get = async (filter?: ComparisonOverviewFilter) => {
  return utils.apiGet<ComparisonOverview>(baseUrl, filter);
};

const getById = async (id: number, archived?: boolean) => {
  return utils.apiGet<Comparison>(`${baseUrl}/${id}`, { archived });
};

const getSteps = async (filter?: StepOverviewFilter) => {
  return utils.apiGet<StepOverview>(`${baseUrl}/steps`, filter);
};

const getStepById = async (id: number) => {
  return utils.apiGet<Step>(`${baseUrl}/step/${id}`);
};

const getFilters = async () => {
  return utils.apiGet<ComparisonFilter>(`${baseUrl}/filters`);
};

const getListFilters = async () => {
  return utils.apiGet<ListFilter>(`${baseUrl}/listfilters`);
};

const getCompareSteps = async (filter?: StepOverviewFilter) => {
  return utils.apiGet<CompareStep>(`${baseUrl}/comparesteps`, filter);
};

const getForecast = async (request: ForecastCriteria) => {
  return utils.apiGet<Forecast>(`${baseUrl}/forecast`, request);
};

const downloadSteps = async (filter?: StepOverviewFilter) => {
  return utils.apiDownload(`${baseUrl}/download/steps`, filter);
};

const upsert = async (comparison: Comparison) => {
  return utils.apiCommand<Comparison, number>(baseUrl, comparison, "POST");
};

const updateStep = async (id: number, step: Step) => {
  return utils.apiCommand(`${baseUrl}/step/${id}`, step, "PUT");
};

const copy = async (id: number) => {
  return utils.apiCommand(`${baseUrl}/copy`, {comparisonId: id}, "POST");
}

const archive = async (id: number) => {
  return utils.apiCommand(`${baseUrl}/archive/${id}`, null, "PUT");
};

const remove = async (id: number) => {
  return utils.apiCommand(`${baseUrl}/${id}`, null, "DELETE");
};

const comparisonService = {
  get,
  getById,
  getSteps,
  getStepById,
  getFilters,
  getListFilters,
  getForecast,
  getCompareSteps,
  downloadSteps,
  upsert,
  updateStep,
  copy,
  archive,
  remove,
};

export default comparisonService;
