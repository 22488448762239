import { useCallback, useState } from "react";

const useTabs = (
  initialState = 0
): [number, (_: React.SyntheticEvent, newTab: number) => void, () => void] => {
  const [state, setState] = useState<number>(initialState);

  const goToTab = useCallback((_, newTab) => setState(newTab), []);
  const reset = useCallback(() => setState(initialState), [initialState]);

  return [state, goToTab, reset];
};

export default useTabs;
