import { Box, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { FormProps } from "./form.props";

type Props<T> = FormProps<T> &
  TextFieldProps & {
    nameSeconds?: string;
    hideSeconds?: boolean;
  };

export const ControlledTimeField = <T extends FieldValues>({
  name,
  watch,
  control,
  label,
  required,
  hideSeconds,
  ...rest
}: Props<T>) => {
  if (!name) return <></>;

  const getValue = (type: "minutes" | "seconds") => {
    const input = watch && watch(name);

    if (typeof input === 'number') {
      if (type === "minutes") {
        return Math.floor(input / 60);
      } else { // type is "seconds"
        return input % 60;
      }
    }
    return 0; // return a default value if input is not a number
  }

  const onChangeCustom = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (...event: any[]) => void, type: "minutes" | "seconds") => {
    const input = (watch && watch(name)) || 0;
    const value = +e.target.value;

    if (typeof input === 'number') {
      if (type === "minutes") {
        onChange(value * 60 + (input % 60));
      } else { // type is "seconds"
        onChange(Math.floor(input / 60) * 60 + value);
      }
    }
  }

  return (
    <Box display="flex">
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur },
          fieldState: { isTouched, error },
        }) => (
          <TextField
            value={getValue("minutes")}
            onChange={(e) => onChangeCustom(e, onChange, "minutes")}
            onBlur={onBlur}
            label={label}
            error={isTouched && Boolean(error?.message)}
            helperText={isTouched && error?.message}
            type="number"
            inputProps={{
              step: "1",
              min: 0,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
            sx={{ mr: hideSeconds ? 0 : 1 }}
            {...rest}
          />
        )}
      />
      {!hideSeconds && (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, onBlur },
            fieldState: { isTouched, error },
          }) => (
            <TextField
              value={getValue("seconds")}
              onChange={(e) => onChangeCustom(e, onChange, "seconds")}
              onBlur={onBlur}
              error={isTouched && Boolean(error?.message)}
              helperText={isTouched && error?.message}
              type="number"
              inputProps={{
                step: "1",
                min: 0,
                max: 59,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">sec</InputAdornment>
                ),
              }}
              {...rest}
            />
          )}
        />
      )}
    </Box>
  );
};

export default ControlledTimeField;
