import {
  AppBar,
  Badge,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { ApplicationType } from "api/models";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectApplication,
  selectApplicationSwitch,
  setApplication,
} from "features/configuration";
import { selectExtraScannerModuleInformationAvailable } from "features/energyComparison";
import { selectT9N } from "features/locale";
import LanguageSelection from "features/locale/LanguageSelection";
import { clearProducts } from "features/product";
import { selectUser, selectActiveModules } from "features/user";
import React from "react";
import { shallowEqual } from "react-redux";
import { NavLink } from "react-router-dom";
import { Image } from "shared/components";
import { useMenuToggle } from "shared/hooks";

const Header = () => {
  const t9n = useAppSelector(selectT9N);
  const user = useAppSelector(selectUser);
  const application = useAppSelector(selectApplication);
  const modules = useAppSelector(
    selectActiveModules(application),
    shallowEqual
  );

  const title = () => {
    if((modules || []).length === 0)
      return t9n?.[application!];

    return modules?.map((m) => t9n?.[m]).join(" | ");
  }

  return (
    <>
      <AppBar
        component="header"
        position="sticky"
        color="transparent"
        sx={{
          pl: 2,
          boxShadow: "none",
          display: "flex",
          width: 1,
          backgroundColor: "secondary.light",
          height: "5rem",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            width: 1,
            minHeight: { xs: 0 },
            justifyContent: "space-between",
            alignItems: "flex-end",
            mt: 1,
          }}
        >
          <Box>
            <LanguageSelection />

            <Typography
              variant="h3"
              sx={{ display: { xs: "none", sm: "block" }, mt: 1 }}
            >
              { title() }
            </Typography>

            <Typography
              variant="h2"
              sx={{ display: { xs: "block", sm: "none" }, mt: 1 }}
            >
              {
                application === ApplicationType.QccSales
                  ? t9n?.Scanners
                  : "QCC"
              }
            </Typography>
          </Box>

          {user ? <UserMenu /> : null}
        </Toolbar>
      </AppBar>
    </>
  );
};

function UserMenu() {
  const [menuAnchorEl, menuOpen, handleMenuClick, handleMenuClose] =
    useMenuToggle();
  const appSwitch = useAppSelector(selectApplicationSwitch);
  const user = useAppSelector(selectUser);
  const t9n = useAppSelector(selectT9N);
  const extraScannerModuleInformationAvailable = useAppSelector(
    selectExtraScannerModuleInformationAvailable
  );

  const dispatch = useAppDispatch();
  const dispatchApplication = (app: ApplicationType) => {
    dispatch(clearProducts());
    dispatch(setApplication(app));
  };

  return (
    <>
      <Button
        onClick={handleMenuClick}
        sx={{
          py: 0,
          display: "flex",
        }}
      >
        <Box sx={{ pr: 1 }}>
          <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
            {user?.name}
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
            {user?.role && t9n?.[user.role]}
          </Typography>
        </Box>

        <Badge
          color="primary"
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          invisible={!extraScannerModuleInformationAvailable}
          sx={{
            "& > img": {
              filter: (theme: any) => theme.palette.primary.filter?.main,
            },
          }}
        >
          <Image fileName="profile.svg" alt="profile" />
        </Badge>

        <Box
          sx={{
            "& > img": {
              filter: (theme: any) => theme.palette.primary.filter?.main,
            },
          }}
        >
          <Image
            fileName="menu.svg"
            alt="menu"
            className={menuOpen ? "turnImg" : ""}
          />
        </Box>
      </Button>

      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
        {appSwitch && appSwitch.length > 0
          ? appSwitch?.map((a, i) => (
              <MenuItem key={i} onClick={() => dispatchApplication(a.app)}>
              {a.app === ApplicationType.QccSales
                ? t9n?.Scanners
                : t9n?.[a.app]}
              </MenuItem>
            ))
          : null}

        <MenuItem component={NavLink} to="/profile" onClick={handleMenuClose}>
          {t9n?.MyProfile}
        </MenuItem>

        <form
          id="logout-form"
          className="form-inline"
          action="auth/logout"
          method="post"
        >
          <MenuItem
            sx={{ color: "error.main" }}
            onClick={() => {
              (
                document.getElementById("logout-form") as HTMLFormElement
              ).submit();
              handleMenuClose();
            }}
          >
            {t9n?.Logout}
          </MenuItem>
        </form>
      </Menu>
    </>
  );
}

export default Header;
