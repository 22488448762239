import { createTheme } from "@mui/material";
createTheme();
export const theme = createTheme({
  palette: {
    primary: {
      main: "#184CA0",
      dark: "#0A306E",
      light: "#0082DC",
      contrastText: "#FFF",
      filter: {
        main: "invert(17%) sepia(34%) saturate(6852%) hue-rotate(213deg) brightness(94%) contrast(82%)",
        light:
          "invert(43%) sepia(99%) saturate(4540%) hue-rotate(186deg) brightness(96%) contrast(101%)",
      },
    },
    secondary: {
      main: "#6C757D",
      light: "#F6F7F9",
      dark: "#555",
      contrastText: "#000",
      filter: {
        main: "invert(47%) sepia(10%) saturate(385%) hue-rotate(167deg) brightness(93%) contrast(88%)",
        light:
          "invert(99%) sepia(10%) saturate(399%) hue-rotate(177deg) brightness(99%) contrast(97%)",
      },
      graph: {
        dark: "#575757",
        main: "#747474",
        light: "#9b9b9b",
      },
    },
    error: {
      main: "#FF5858",
      filter: {
        main: "filter: invert(59%) sepia(88%) saturate(5224%) hue-rotate(333deg) brightness(121%) contrast(106%)",
      },
      graph: {
        dark: "#b8242a",
        main: "#e84f25",
        light: "#f19820",
      },
    },
    success: {
      light: "#92d050",
      main: "#66bb6a",
      graph: {
        dark: "#206069",
        main: "#2da076",
        light: "#71ae6b",
      },
    },
    warning: {
      main: "#ffc000",
    },
    graph: {
      preparation: { main: "#D9D9D9" },
      undercoat: { main: "#F79834" },
      basecoat: { main: "#0982D8" },
      clearcoat: { main: "#9F0654", light: "#BC5187", dark: "#6F043B" },
    },
  },
  typography: (palette) => ({
    fontFamily: ["Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "1.8em",
      fontWeight: "bold",
      paddingBottom: "1em",
      color: palette?.primary?.main,
    },
    h2: {
      fontSize: "1.6em",
      fontWeight: "bold",
      color: palette?.primary?.main,
    },
    h3: {
      fontSize: "1.4em",
      fontWeight: "bold",
      color: palette?.primary?.main,
    },
    h4: {
      fontSize: "1.2em",
      fontWeight: "bold",
      paddingBottom: "0.5em",
      color: palette?.primary?.main,
    },
    h5: {
      fontSize: "1.1em",
    },
    h6: {
      fontSize: "1em",
    },
    subtitle1: {
      fontWeight: "bold",
    },
    body2: {
      fontSize: "0.8em",
    },
  }),
  components: {
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: "1px solid #DDD",
          bordeRadius: "12px",
          boxShadow: "0 2px 8px 0 rgba(208,208,208,0.5)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
        },
        paddingCheckbox: {
          padding: "2px 4px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
    },
  },
});

window.Apex = {
  colors: [theme.palette.secondary.main, theme.palette.primary.main],
  chart: { fontFamily: theme.typography.fontFamily },
};
