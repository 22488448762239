import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicationType,
  Brand,
  Country,
  CountryFilter,
  Product,
  ProductDetail,
  SetUserRole,
  User,
} from "api/models";

import {
  deleteBrand,
  deleteCountry,
  deleteUser,
  getBrands,
  getCountries,
  getCountryFilters,
  getProductDetails,
  getProducts,
  getUsers,
  setUserRole,
  upsertCountry,
} from "./actions";

export type ConfigurationState = {
  application?: ApplicationType;
  users?: User[];
  countries?: Country[];
  countryFilters?: CountryFilter;
  brands?: Brand[];
  products?: Product[];
  productDetails?: ProductDetail[];
};

const initialState: ConfigurationState = {};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setApplication: (state, action: PayloadAction<ApplicationType>) => {
      state.application = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUsers.fulfilled,
      (state, action: PayloadAction<User[] | null>) => {
        if (action.payload) state.users = action.payload;
      }
    );
    builder.addCase(
      setUserRole.fulfilled,
      (state, action: PayloadAction<SetUserRole>) => {
        const user = state.users?.find((u) => u.id === action.payload.userId);

        if (user) user.role = action.payload.role;
      }
    );
    builder.addCase(
      deleteUser.fulfilled,
      (state, action: PayloadAction<number>) => {
        const index = state.users?.findIndex((u) => u.id === action.payload);
        if (index !== undefined && index > -1) state.users?.splice(index, 1);
      }
    );
    builder.addCase(
      getCountries.fulfilled,
      (state, action: PayloadAction<Country[] | null>) => {
        if (action.payload) state.countries = action.payload;
      }
    );
    builder.addCase(
      getCountryFilters.fulfilled,
      (state, action: PayloadAction<CountryFilter | null>) => {
        if (action.payload) state.countryFilters = action.payload;
      }
    );
    builder.addCase(
      upsertCountry.fulfilled,
      (state, action: PayloadAction<Country>) => {
        const code = action.payload.code?.trim()?.toUpperCase();
        const country = state.countries?.find((c) => c.code === code);
        if (country) {
          country.name = action.payload.name;
          country.currency = action.payload.currency;
          country.hourlyLaborCost = action.payload.hourlyLaborCost;
          country.hourlyCoatingRoomCost = action.payload.hourlyCoatingRoomCost;
          country.hourlyDryingRoomCost40Degrees =
            action.payload.hourlyDryingRoomCost40Degrees;
          country.hourlyDryingRoomCost60Degrees =
            action.payload.hourlyDryingRoomCost60Degrees;
          country.hourlyIrCost = action.payload.hourlyIrCost;
          country.averageExternalAirTemperature =
            action.payload.averageExternalAirTemperature;
          country.sprayTemperature = action.payload.sprayTemperature;
          country.naturalGasCost = action.payload.naturalGasCost;
          country.electricityCost = action.payload.electricityCost;
          country.averageTopCoatJobsPerWeek =
            action.payload.averageTopCoatJobsPerWeek;
          country.numberOfWeeksPerYear = action.payload.numberOfWeeksPerYear;
          country.averageRevenuePerDamage =
            action.payload.averageRevenuePerDamage;
        } else {
          state.countries?.push({ ...action.payload, code: code });
        }
      }
    );
    builder.addCase(
      deleteCountry.fulfilled,
      (state, action: PayloadAction<string>) => {
        const index = state.countries?.findIndex(
          (c) => c.code === action.payload
        );
        if (index !== undefined && index > -1)
          state.countries?.splice(index, 1);
      }
    );
    builder.addCase(
      getBrands.fulfilled,
      (state, action: PayloadAction<Brand[] | null>) => {
        if (action.payload) state.brands = action.payload;
      }
    );
    builder.addCase(
      deleteBrand.fulfilled,
      (state, action: PayloadAction<number>) => {
        const index = state.brands?.findIndex((b) => b.id === action.payload);
        if (index !== undefined && index > -1) state.brands?.splice(index, 1);
      }
    );
    builder.addCase(
      getProducts.fulfilled,
      (state, action: PayloadAction<Product[] | undefined | null>) => {
        if (action.payload) state.products = action.payload;
        else state.products = undefined;
      }
    );
    builder.addCase(
      getProductDetails.fulfilled,
      (state, action: PayloadAction<ProductDetail[] | undefined | null>) => {
        if (action.payload) state.productDetails = action.payload;
        else state.productDetails = undefined;
      }
    );
  },
});

export const { setApplication } = configurationSlice.actions;
export default configurationSlice.reducer;
