import { Button, Typography } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React from "react";

type Props = {
  title?: string;
  download: () => void;
};

const Download = ({ title, download }: Props) => {
  const t9n = useAppSelector(selectT9N);
  return (
    <>
      {title && (
        <Typography variant="body1" sx={{ pb: 2 }}>
          {title}
        </Typography>
      )}

      <Button variant="contained" onClick={download}>
        {t9n?.Download}
      </Button>
    </>
  );
};

export default Download;
