import { BaseSelectProps, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Image } from 'shared/components';

import { FormProps, Options } from './form.props';

type Props<T> = FormProps<T> &
BaseSelectProps & {
    isClearable?: boolean;
    options?: Options[];
  };

const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  isClearable,
  options,
  children,
  ...rest
}: React.PropsWithChildren<Props<T>>) => {
  if (!name) return <></>;

  const { label, fullWidth, size } = rest;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value, },
        fieldState: { isTouched, error },
      }) => (
        <FormControl fullWidth={fullWidth} size={size} sx={{ mt: 1, mb: 1 / 2 }}>
          {label && <InputLabel>{label}</InputLabel>}
          <MuiSelect
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlur}
            error={isTouched && Boolean(error?.message)}
            endAdornment={
              <IconButton
                onMouseDown={(event) => {
                  // stops the popup from appearing when this button is clicked
                  event.stopPropagation();
                }}
                onClick={() => {
                  onChange({ target: { value: "" } })
                }}
                sx={{ m: 0, p: 0, display: (value && isClearable) ? '' : 'none' }}>
                <Image fileName="clear.svg" alt="Clear" width={10} color='secondary' />
              </IconButton>}
            sx={{ "& .MuiSelect-iconOutlined": { display: (value && isClearable) ? 'none' : '' } }}
            {...rest}
          >
            {options?.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
            {children}
          </MuiSelect>
          {isTouched && Boolean(error?.message) && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ControlledSelect;
