export enum Role {
  Administrator = "Administrator",
  Advanced = "Advanced",
  Standard = "Standard",
  Dealer = "Dealer",
}

export enum ApplicationType {
  Qcc = "Qcc",
  QccSales = "QccSales",
  EnergyScanner = "EnergyScanner",
  ProfitScanner = "ProfitScanner",
  VocScanner = "VocScanner",
}

export enum UploadStatus {
  Pending = "pending",
  Done = "done",
  Error = "error",
}

export enum ProductType {
  Undercoat = "Undercoat",
  Basecoat = "Basecoat",
  Clearcoat = "Clearcoat",
}

export enum EnergyProductType {
  Preparation = "Preparation",
  Undercoat = "Undercoat",
  Basecoat = "Basecoat",
  Clearcoat = "Clearcoat",
}

export enum EnergyDryingMode {
  AirDry = "AirDry",
  Bake = "Bake",
}

export enum BaseCoatType {
  TwoStage = "TwoStage",
  ThreeStage = "ThreeStage",
}

export enum WorkType {
  Car = "Car",
  Academy = "Academy",
}

export enum AcademyRepairType {
  CTCPanel = "CTCPanel",
  HalfSquareMeterPanel = "HalfSquareMeterPanel",
  SquareMeterPanel = "SquareMeterPanel",
}

export enum CarRepairType {
  SpotRepair = "SpotRepair",
  PanelRepair = "PanelRepair",
  MultiPanelRepair = "MultiPanelRepair",
  OverallRepair = "OverallRepair",
}

export enum Mode {
  Application = "Application",
  Flash = "Flash",
  Drying = "Drying",
}

export enum Layer {
  GroundCoat = "GroundCoat",
  EffectCoat = "EffectCoat",
  NoCoat = "NoCoat",
}

export enum CostCategory {
  Product = "Product",
  Labor = "Labor",
  Energy = "Energy",
}
