const number = (
  value: any,
  decimals: number = 2,
) => {
  if (!Number(value)) return value;

  return Number(value).toLocaleString(navigator.language, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: 0,
  });
};

const percentage = (
  value: any,
  decimals: number = 2,
) => {
  if (!Number(value)) return value

  return `${number(Number(value) * 100, decimals)} %`;
};

const difference = (
  value: any,
  decimals: number = 2,
  otherReturnvalue: string = "="
) => {
  if (!Number(value)) return otherReturnvalue;

  return `${Number(value) > 0 ? "▲" : "▼"} ${percentage(
    Number(value),
    decimals,
  )}`;
};

const currency = (
  value: any,
  currency?: string,
  decimals: number = 2,
) => {
  if (!Number(value)) return value;

  return currency
    ? `${number(Number(value), decimals)} ${currency}`
    : number(Number(value), decimals);
};

const formatService = {
  number,
  percentage,
  difference,
  currency,
};
export default formatService;
