import { ApplicationType, Role } from "api/models";
import { RoutePathDefinition } from "app/routing";
import RequireAuth from "features/authentication/RequireAuth";
import Unauthorized from "features/authentication/Unauthorized";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const Dashboard = React.lazy(
  () => import("../../features/dashboard/Dashboard")
);
const MyProfile = React.lazy(() => import("../../features/user/MyProfile"));
const Comparison = React.lazy(
  () => import("../../features/comparison/components/Comparison")
);
const EditComparison = React.lazy(
  () => import("../../features/comparison/components/EditComparison")
);
const EnergyComparison = React.lazy(
  () => import("../../features/energyComparison/components/EnergyComparison")
);
const EditEnergyComparison = React.lazy(
  () =>
    import("../../features/energyComparison/components/EditEnergyComparison")
);
const UserConfiguration = React.lazy(
  () => import("../../features/configuration/components/UserConfiguration")
);
const CountryConfiguration = React.lazy(
  () => import("../../features/configuration/components/CountryConfiguration")
);
const ProductConfiguration = React.lazy(
  () => import("../../features/configuration/components/ProductConfiguration")
);
const Archive = React.lazy(() => import("../../features/archive/Archive"));
const EnergyArchive = React.lazy(
  () => import("../../features/energyArchive/Archive")
);
const ReportDetails = React.lazy(
  () => import("../../features/comparison/components/ReportDetails")
);
const EnergyReportDetails = React.lazy(
  () => import("../../features/energyComparison/components/ReportDetails")
);
const Compare = React.lazy(() => import("../../features/compare/Compare"));
const DownloadReport = React.lazy(
  () => import("../../features/comparison/components/DownloadReport")
);
const DownloadEnergyReport = React.lazy(
  () => import("../../features/energyComparison/components/DownloadReport")
);

export const routes: RoutePathDefinition[] = [
  {
    path: "/",
    title: "Dashboard",
    alias: "Home",
    icon: "dashboard.svg",
    use: {
      nav: true,
      order: 2,
      requiredApp: ApplicationType.Qcc,
    },
    element: (
      <RequireAuth requiredApp={ApplicationType.Qcc}>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: "/new",
    title: "New",
    alias: "NewComparison",
    icon: "new-comparison.svg",
    use: {
      nav: true,
      order: 1,
      requiredApp: ApplicationType.Qcc,
    },
    element: (
      <RequireAuth requiredApp={ApplicationType.Qcc}>
        <Comparison />
      </RequireAuth>
    ),
  },
  {
    path: "/energy/new",
    title: "New",
    alias: "NewComparison",
    icon: "new-comparison.svg",
    use: {
      nav: true,
      order: 1,
      requiredApp: ApplicationType.QccSales,
    },
    element: (
      <RequireAuth requiredApp={ApplicationType.QccSales}>
        <EnergyComparison />
      </RequireAuth>
    ),
  },
  {
    path: "/archive",
    title: "Archive",
    icon: "archive.svg",
    use: {
      nav: true,
      order: 3,
      requiredApp: ApplicationType.Qcc,
    },
    element: <Outlet />,
    children: [
      {
        path: "",
        title: "",
        use: {
          requiredApp: ApplicationType.Qcc,
        },
        element: (
          <RequireAuth requiredApp={ApplicationType.Qcc}>
            <Archive />
          </RequireAuth>
        ),
      },
      {
        path: "report",
        title: "Report",
        use: {
          requiredApp: ApplicationType.Qcc,
        },
        element: <Outlet />,
        children: [
          {
            path: ":comparisonId",
            title: "",
            use: {
              requiredApp: ApplicationType.Qcc,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.Qcc}>
                <ReportDetails />
              </RequireAuth>
            ),
          },
          {
            path: "download/:comparisonId",
            title: "Download",
            use: {
              requiredApp: ApplicationType.Qcc,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.Qcc}>
                <DownloadReport />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "edit",
        title: "EditComparison",
        use: {
          requiredApp: ApplicationType.Qcc,
        },
        element: <Outlet />,
        children: [
          {
            path: ":comparisonId",
            title: "",
            use: {
              requiredApp: ApplicationType.Qcc,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.Qcc}>
                <EditComparison />
              </RequireAuth>
            ),
          },
          {
            path: "download/:comparisonId",
            title: "Download",
            use: {
              requiredApp: ApplicationType.Qcc,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.Qcc}>
                <DownloadReport />
              </RequireAuth>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/energy/archive",
    title: "Archive",
    icon: "archive.svg",
    use: {
      nav: true,
      order: 2,
      requiredApp: ApplicationType.QccSales,
    },
    element: <Outlet />,
    children: [
      {
        path: "",
        title: "",
        use: {
          requiredApp: ApplicationType.QccSales,
        },
        element: (
          <RequireAuth requiredApp={ApplicationType.QccSales}>
            <EnergyArchive />
          </RequireAuth>
        ),
      },
      {
        path: "report",
        title: "Report",
        use: {
          requiredApp: ApplicationType.QccSales,
        },
        element: <Outlet />,
        children: [
          {
            path: ":comparisonId",
            title: "",
            use: {
              requiredApp: ApplicationType.QccSales,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.QccSales}>
                <EnergyReportDetails />
              </RequireAuth>
            ),
          },
          {
            path: "download/:comparisonId",
            title: "Download",
            use: {
              requiredApp: ApplicationType.QccSales,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.QccSales}>
                <DownloadEnergyReport />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "edit",
        title: "EditComparison",
        use: {
          requiredApp: ApplicationType.QccSales,
        },
        element: <Outlet />,
        children: [
          {
            path: ":comparisonId",
            title: "",
            use: {
              requiredApp: ApplicationType.QccSales,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.QccSales}>
                <EditEnergyComparison />
              </RequireAuth>
            ),
          },
          {
            path: "download/:comparisonId",
            title: "Download",
            use: {
              requiredApp: ApplicationType.QccSales,
            },
            element: (
              <RequireAuth requiredApp={ApplicationType.QccSales}>
                <DownloadEnergyReport />
              </RequireAuth>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/compare",
    title: "CompareSteps",
    icon: "compare.svg",
    use: {
      nav: true,
      order: 4,
      requiredApp: ApplicationType.Qcc,
      requiredRoles: [Role.Administrator, Role.Advanced, Role.Standard],
    },
    element: (
      <RequireAuth
        requiredApp={ApplicationType.Qcc}
        requiredRoles={[Role.Administrator, Role.Advanced, Role.Standard]}
      >
        <Compare />
      </RequireAuth>
    ),
  },
  {
    path: "/settings",
    title: "Settings",
    icon: "settings.svg",
    use: {
      nav: true,
      order: 5,
      requiredRoles: [Role.Administrator],
    },
    children: [
      {
        path: "users",
        title: "Users",
        use: {
          nav: true,
          requiredRoles: [Role.Administrator],
        },
        element: (
          <RequireAuth requiredRoles={[Role.Administrator]}>
            <UserConfiguration />
          </RequireAuth>
        ),
      },
      {
        path: "countries",
        title: "Countries",
        use: {
          nav: true,
          requiredRoles: [Role.Administrator],
        },
        element: (
          <RequireAuth requiredRoles={[Role.Administrator]}>
            <CountryConfiguration />
          </RequireAuth>
        ),
      },
      {
        path: "products",
        title: "Products",
        use: {
          nav: true,
          requiredRoles: [Role.Administrator],
        },
        element: (
          <RequireAuth requiredRoles={[Role.Administrator]}>
            <ProductConfiguration />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/profile",
    title: "MyProfile",
    element: (
      <RequireAuth>
        <MyProfile />
      </RequireAuth>
    ),
  },
  {
    path: "/unauthorized",
    title: "Unauthorized",
    element: <Unauthorized />,
    use: {
      hideBreadCrumb: true,
    },
  },
  {
    title: "No match",
    path: "*",
    element: <Navigate to="/" replace />,
  },
];
