import {
  Brand,
  Country,
  CountryFilter,
  CsvProcessResult,
  Product,
  ProductDetail,
  SetUserRole,
  UploadStatus,
  User,
} from "api/models";
import utils from "api/utils";

const baseUrl = "api/configuration";

const getUsers = async () => {
  return utils.apiGet<User[]>(`${baseUrl}/users`);
};

const setUserRole = async ({ userId, role }: SetUserRole) => {
  return utils.apiCommand(`${baseUrl}/userrole/${userId}`, { role }, "PUT");
};

const deleteUser = async (userId: number) => {
  return utils.apiCommand(`${baseUrl}/user/${userId}`, null, "DELETE");
};

const getCountries = async () => {
  return utils.apiGet<Country[]>(`${baseUrl}/countries`);
};

const getCountryFilters = async () => {
  return utils.apiGet<CountryFilter>(`${baseUrl}/country/filter`);
};

const upsertCountry = async (
  countryCode: string | undefined,
  country: Country
) => {
  return utils.apiCommand(`${baseUrl}/country/${countryCode}`, country, "POST");
};

const deleteCountry = async (code: string) => {
  return utils.apiCommand(`${baseUrl}/country/${code}`, null, "DELETE");
};

const getBrands = async () => {
  return utils.apiGet<Brand[]>(`${baseUrl}/brands`);
};

const addBrand = async (name: string) => {
  return utils.apiCommand(`${baseUrl}/brand`, { name }, "POST");
};

const deleteBrand = async (brandId: number) => {
  return utils.apiCommand(`${baseUrl}/brand/${brandId}`, null, "DELETE");
};

const getProducts = async (brandId: number, language?: string) => {
  return utils.apiGet<Product[]>(`${baseUrl}/products`, { brandId, language });
};

const getProductDetails = async (brandId: number, language?: string) => {
  return utils.apiGet<ProductDetail[]>(`${baseUrl}/productdetails`, {
    brandId,
    language,
  });
};

const downloadProducts = async (brandId: number) => {
  return utils.apiDownload(`${baseUrl}/download/products/${brandId}`, null);
};

const downloadProductDetails = async (brandId: number, language?: string) => {
  return utils.apiDownload(
    `${baseUrl}/download/products/details/${brandId}/${language}`,
    null
  );
};

const downloadProductVoc = async (brandId: number) => {
  return utils.apiDownload(
    `${baseUrl}/download/products/voc/${brandId}`,
    null
  );
};

const uploadProducts = async (
  brandId: number,
  file: Blob | File,
  onUploadProgress: (
    progress: number,
    status: UploadStatus,
    result?: CsvProcessResult
  ) => void
) => {
  return utils.apiUpload(
    `${baseUrl}/upload/products/${brandId}`,
    file,
    onUploadProgress
  );
};

const uploadProductDetails = async (
  brandId: number,
  file: Blob | File,
  onUploadProgress: (
    progress: number,
    status: UploadStatus,
    result?: CsvProcessResult
  ) => void
) => {
  return utils.apiUpload(
    `${baseUrl}/upload/products/details/${brandId}`,
    file,
    onUploadProgress
  );
};

const uploadProductVoc = async (
  brandId: number,
  file: Blob | File,
  onUploadProgress: (
    progress: number,
    status: UploadStatus,
    result?: CsvProcessResult
  ) => void
) => {
  return utils.apiUpload(
    `${baseUrl}/upload/products/voc/${brandId}`,
    file,
    onUploadProgress
  );
};


const configurationService = {
  getUsers,
  setUserRole,
  deleteUser,
  getCountries,
  getCountryFilters,
  upsertCountry,
  deleteCountry,
  getBrands,
  addBrand,
  deleteBrand,
  getProducts,
  getProductDetails,
  downloadProducts,
  downloadProductDetails,
  downloadProductVoc,
  uploadProducts,
  uploadProductDetails,
  uploadProductVoc
};
export default configurationService;
