import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  TextField as MuiTextField,
} from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { FormProps } from "./form.props";

type Props<T> = FormProps<T> &
  Omit<AutocompleteProps<T, boolean, boolean, boolean, React.ElementType<any>>, "renderInput"> & {
    label?: string;
    required?: boolean;
  };

export const ControlledAutoComplete = <T extends FieldValues>({
  name,
  control,
  options,
  label,
  required,
  onInputChange: handleInputChange,
  onChange: handleChange,
  ...rest
}: Props<T>) => {
  if (!name) return <></>;
  
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { isTouched, error },
      }) => (
        <MuiAutocomplete
          value={value ?? ""}
          onInputChange={(event, value) => {
            onChange(value);
            handleInputChange && handleInputChange(event, value, "input");
          }}
          onChange={(event, item) => {
            onChange(item);
            handleChange && handleChange(event, item, "selectOption");
          }}
          onBlur={onBlur}
          options={options?.filter((v, i, a) => a.indexOf(v) === i) ?? []}
          {...rest}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label={label}
              size="small"
              margin="dense"
              required={required}
              error={isTouched && Boolean(error?.message)}
              helperText={isTouched && error?.message}
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutoComplete;
