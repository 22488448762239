import "./Upload.css";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React, { useRef, useState } from "react";
import { Image } from "shared/components";

type State = {
  highlight: boolean;
  fileIsRejected?: boolean;
};
type Props = {
  disabled: boolean;
  accept: string;
  onFileAdded: (file: File) => void;
};

const DropZone = ({ disabled, accept, onFileAdded }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<State>({ highlight: false });
  const t9n = useAppSelector(selectT9N);

  const openFileDialog = () => {
    if (disabled) return;
    fileInputRef?.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || disabled || isFileRejected(files)) return;

    onFileAdded(files[0]);

    setState({ ...state, fileIsRejected: false });
  };

  const isFileRejected = (files: FileList | DataTransferItemList) => {
    let returnVal = false;

    if (files instanceof FileList) {
      Array.from(files).forEach((file) => {
        if (!accept.includes(file.type)) {
          setState({ ...state, fileIsRejected: true });
          return true;
        }
        return false;
      });
    } else {
      Object.keys(files).forEach((key: string) => {
        if (!accept.includes(files[+key].type)) {
          setState({ ...state, fileIsRejected: true });
          returnVal = true;
        }
      });
    }
    return returnVal;
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (disabled) return;

    setState({ ...state, highlight: true });

    if (isFileRejected(e.nativeEvent.dataTransfer!.items)) {
      return;
    }
  };

  const onDragLeave = () => {
    setState({ ...state, highlight: false, fileIsRejected: false });
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (disabled || isFileRejected(e.nativeEvent.dataTransfer!.items)) {
      onDragLeave();
      return;
    }
    const files = e.dataTransfer.files;
    if (files) {
      onFileAdded(files[0]);
    }
    setState({ ...state, highlight: false });
  };

  const { highlight, fileIsRejected } = state;

  return (
    <Box
      color="divider"
      className={"dropzone" + (highlight ? " highlight" : "")}
      bgcolor={fileIsRejected ? "error.light" : ""}
      onClick={openFileDialog}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      sx={{ cursor: disabled ? "default" : "pointer", borderRadius: 2 }}
    >
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        onChange={handleFileChange}
        ref={fileInputRef}
      />

      <Image fileName="upload.svg" className="icon" alt="upload"></Image>

      <Typography variant="subtitle1" color="secondary">
        {!highlight && !fileIsRejected && t9n?.ClickToUpload}
        {highlight && !fileIsRejected && t9n?.DropToUpload}
        {fileIsRejected && t9n?.FileTypeInvalid}
      </Typography>
    </Box>
  );
};

export default DropZone;
