import {
  Checkbox as MuiCheckBox,
  CheckboxProps,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { FormProps } from "./form.props";

type Props<T> = FormProps<T> &
  CheckboxProps & {
    label?: string;
  };

export const ControlledCheckBox = <T extends FieldValues>({
  name,
  label,
  control,
  ...rest
}: Props<T>) => {
  if (!name) return <></>;

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <MuiCheckBox
              value={value ?? ""}
              checked={!!value}
              onChange={onChange}
              {...rest}
            />
          )}
        />
      }
      label={label ?? ""}
    />
  );
};

export default ControlledCheckBox;
