import utils from "api/utils";

const baseUrl = "api/report";

const downloadCertificate = async (comparisonId: number, language?: string) => {
    return utils.apiDownload(`${baseUrl}/certificate/${comparisonId}`, { language });
};

const reportService = {
    downloadCertificate,
};
export default reportService;